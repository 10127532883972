<template>
  <v-col>
    <v-col class="mb-0 pb-0" align=center>
      <h1 class="mb-0 pb-0" style="background-color: #7986CB;color: white;  border-radius:10px">
        Création de contrat
      </h1>
    </v-col>
    <Steppy v-model:step="step" doneText="Créer mon contrat" nextText="Suivant" backText="Retour" :tabs="tabs"
      :finalize="finalize" :primaryColor1="'#7986CB'" :primaryColor2="'#ECEFF1'">
      <template #1>
        <v-col cols="12">
          <h3>Information client</h3>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="12">
              <v-autocomplete v-model="form['client']" class="edition" :items="clientListe" item-title="SOCIETE"
                return-object label="Client" hide-details>
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form['client']['VILLE']" readonly class="disabledFormData" variant="solo"
                hide-details label="ville">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form['client']['CONTACT_MAIL(1)']" class="disabledFormData" variant="solo" readonly
                hide-details label="Mail">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form['client']['ADRESSE']" class="disabledFormData" variant="solo" readonly
                hide-details label="Adresse">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form['client']['CODE_POSTAL']" class="disabledFormData" variant="solo" readonly
                hide-details label="Code postal">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form['client']['TELEPHONE']" class="disabledFormData" variant="solo" readonly a
                hide-details label="Téléphone">
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </template>
      <template #2>
        <v-col cols="12">
          <h3>Information Materiel</h3>
        </v-col>
        <v-col cols="12">
          <v-combobox class="edition" v-model="form['family']" return-object item-title="WEB_NOM_FAMILLE"
            :items="familyListe" hide-details label="Famille">
          </v-combobox>
        </v-col>
        <v-col cols="12">
          <v-combobox v-if="form['family']" class="edition" v-model="form['subFamily']" return-object
            item-title="fieldData.titre_1"
            :items="subFamilyListe.filter((val) => val.fieldData['Famille Parc'] == form['family']['Nom_Famille_Principale'])"
            hide-details label="Sous famille">
          </v-combobox>
        </v-col>
      </template>
      <template #3>
        <v-col cols="12">
          <h3>Sélection de Materiel</h3>
        </v-col>
                <v-col>
          <v-row>
            <v-col cols="12">
              <v-combobox class="edition" v-model="form['materiel']" return-object item-title="MATRICULE"
                :items="parcListe.filter((val) => val.SOUS_FAMILLE == form['subFamily'].fieldData['Nom_Genre_Machine'])"
                hide-details label="Matricule">
              </v-combobox>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form['materiel']['FAMILLE']" variant="solo" readonly hide-details label="Famille">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form['materiel']['SOUS_FAMILLE']" variant="solo" readonly hide-details
                label="Sous famille">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form['materiel']['NUMERO_SERIE']" variant="solo" readonly hide-details
                label="Numéro de série">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form['materiel']['MARQUE']" variant="solo" readonly hide-details label="Marque">
              </v-text-field>
            </v-col>
            <v-col v-if="form['materiel']">
              <p align="left">
                {{ form['subFamily'].titre_2 }}
              </p>
            </v-col>
          </v-row>
        </v-col>
      </template>
      <template #4>
        <v-col cols="12">
          <h3>Type de location</h3>
        </v-col>
        <v-col align=center>
          <v-select v-model="form['subscription']" :items="getContratSelectedInformations.portalData.CAT_Tarif_GM_id"
            return-object item-title="CAT_Tarif_GM_id::Nom_Grille">
          </v-select>
          <v-card class="pa-2" variant="text">
            Prix : {{ form['subscription']['CAT_Tarif_GM_id::T1_Tarif'].toFixed(2) }} €
          </v-card>
        </v-col>
      </template>
      <template #5>
        <v-col cols="12">
          <h3>Date de début de location</h3>
        </v-col>
        <v-col>
          <input class="pa-2" style="border: solid 1px lightgrey;border-radius: 5px;" v-model="form['date']" type="date">
        </v-col>
      </template>
    </Steppy>
  </v-col>
</template>

<script>

import { Steppy } from 'vue3-steppy'
// import 'vue-step-wizard/dist/vue-step-wizard.css'
import { ref } from "@vue/reactivity";
import { watch } from "vue";

export default {
  props: {
    clientListe: {},
    parcListe: {},
    familyListe: {},
    subFamilyListe: {},
    contrat_informations: {}
  },
  setup() {
    const form = ref({})
    const step = ref(1)
    const tabs = ref([
      {
        key: 'client',
        title: 'Client',
        iconSuccess: null,
        isValid: false
      },
      {
        key: 'subFamily',
        title: 'Category',
        iconSuccess: null,
        isValid: false
      },
      {
        key: 'materiel',
        title: 'Materiel',
        iconSuccess: null,
        isValid: false
      },
      {
        key: 'subscription',
        title: 'Abonnement',
        iconSuccess: null,
        isValid: false
      },
      {
        key: 'date',
        title: 'Date',
        iconSuccess: null,
        isValid: false
      }
    ])

    watch(() => form, (currentValue) => {
      let formKeysTab = Object.keys(currentValue.value)
      for (let i = 0; i < formKeysTab.length; i++) {
        let tmpKey = formKeysTab[i]
        let findedElem = tabs.value.find((val) => val.key == tmpKey)

        if (findedElem) {
          findedElem['isValid'] = true
        }
      }

    },
      { deep: true }
    );

    return { form, step, tabs }
  },
  components: {
    Steppy
  },
  methods: {
    finalize() {
      console.log("it's done");
    }
  },
  computed: {
    getContratSelectedInformations() {
      let elem = this.contrat_informations.find((val) => val.fieldData.Nom_Genre_Machine == this.form.subFamily.fieldData.Nom_Genre_Machine)
      if (elem)
        return elem
      else
        return undefined
    },
    getAccListe() {
      if (this.getSelectedSubFamilyData) {
        return this.getSelectedSubFamilyData.portalData.ComplementLocation
      } else return undefined
    },
    getSubscriptionListe() {
      if (this.getSelectedSubFamilyData) {
        return this.getSelectedSubFamilyData.portalData.CAT_Tarif_GM_id
      } else return undefined
    },
    getSelectedSubFamilyData() {
      if (this.form['subFamily']) {
        return this.contrat_informations.find((val) => val.fieldData.Nom_Genre_Machine == this.form['subFamily'].Nom_Genre_Machine)
      } else
        return undefined
    }
  }
}
</script>

<style></style>