var L = require('leaflet');
require('leaflet-routing-machine');

import "leaflet/dist/leaflet.css";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
// import { onMounted } from "vue";
import { onMounted, watch } from "vue";
import { v4 as uuidv4 } from 'uuid';

import moment from 'moment'
import _ from 'lodash';

// import { OpenStreetMapProvider } from 'leaflet-geosearch';

import engine from "../core/DataApi"
// const provider = new OpenStreetMapProvider();

export default function mapsSettings() {
    const agencySelected = ref(undefined)
    const gpsPositionByClient = ref({})
    const map = ref(null);
    const itineraire = ref({});
    const itineraireTab = ref([]);
    const markerListe = ref([]);
    const unknowAd = ref({});
    const itineraireDataSaved = ref({});
    const itinerairePathSaved = ref({});
    const itineraireOrder = ref({ generalInformation: {}, data: [] });
    const itineraireValidTab = ref(undefined)
    const itineraireOrderLastVue = ref([])
    const agencyFilter = ref(undefined);
    const driversListe = ref(['Didier', 'Thomas', 'Christian'])
    const typeListe = ref(['Livraison', 'Reprise'])
    const eventsColor = ref({ 'Livraison': '#5C6BC0', 'Reprise': '#66BB6A' })
    const position = ref([]);

    watch(
        itineraireOrder,
        (val, old) => {
            if (val == undefined || val.data.length == 0 || old.data.length !== val.data.length) {
                clearMap()
            }
        }, {
            deep: true,
        }
    )
    onMounted(() => {
        engine.get_agency_soft(function(data) {
            if (data.code === 0) {
                let sortedData = {}
                let agency = data.data.data.map((val) =>
                    ({
                        key: val.fieldData['ag_nom'] + '_' + val.fieldData['map_position_x'] + '|' + val.fieldData['map_position_y'],
                        name: val.fieldData['ag_nom'],
                        posX: val.fieldData['map_position_x'],
                        posY: val.fieldData['map_position_y']
                    }));
                if (agency.length > 0) {
                    for (let i = 0; i < agency.length; i++) {
                        let key = agency[i].key
                        if (sortedData[key] == undefined) {
                            let posX = agency[i].posX
                            let posY = agency[i].posY
                            let client = agency[i].name
                            if (posX && posX.length != 0 && posY && posY.length != 0) {
                                sortedData[key] = { client, posX, posY, id: client + '_' + posX + '|' + posY, data: [] }
                            }
                            // else {
                            //     console.log("ERROR : ", client, { posX, posY });
                            // }
                        }
                    }
                }
                engine.get_contrat_actif(function(data) {
                    if (data.code == 0) {
                        let clientsData = data.data.data.map((val) => val.fieldData)
                        for (let i = 0; i < clientsData.length; i++) {
                            let key = clientsData[i]['CLD_CLIENT_RAISON_SOCIALE'] + '_' + clientsData[i]['CLD_POSITION_GPS_ADRES_LIV']
                            let posX = clientsData[i]['CLD_POSITION_GPS_ADRES_LIV'].split('|')[0]
                            let posY = clientsData[i]['CLD_POSITION_GPS_ADRES_LIV'].split('|')[1]
                            let client = clientsData[i]['CLD_CLIENT_RAISON_SOCIALE']

                            if (posX && posX.length != 0 && posY && posY.length != 0) {
                                if (sortedData[key] == undefined) {
                                    sortedData[key] = { client, posX, posY, id: client + '_' + posX + '|' + posY, dataSortie: [], dataStock: [] }
                                }
                                if (clientsData[i].CLD_VISU_DEPART_RETOUR == "Sortie")
                                    sortedData[key].dataSortie.push(clientsData[i])
                                if (clientsData[i].CLD_VISU_DEPART_RETOUR == "Stock")
                                    sortedData[key].dataStock.push(clientsData[i])
                            }
                            //  else {
                            //     console.log("ERROR : ", client, { posX, posY }, "index : ", i);
                            // }
                        }
                        gpsPositionByClient.value = sortedData
                        addAllMarkerToMaps()
                    }
                })
            }
        })
    })

    const addAllMarkerToMaps = function() {
        let gpsPositionByClientTab = Object.values(gpsPositionByClient.value)
        map.value = L.map('mapContainer').setView([gpsPositionByClientTab[0].posX, gpsPositionByClientTab[0].posY], 9);
        L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {}).addTo(map.value);
        var customPane = map.value.createPane("customPane");
        customPane.style.zIndex = 399;

        var greenIcon = L.icon({
            iconUrl: 'map.png',
            // shadowUrl: 'map.png',
            iconSize: [50, 50], // size of the icon
            shadowSize: [50, 64], // size of the shadow
            iconAnchor: [25, 40], // point of the icon which will correspond to marker's location
            shadowAnchor: [4, 62], // the same for the shadow
            popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
        });

        for (let i = 0; i < gpsPositionByClientTab.length; i++) {
            let position = gpsPositionByClientTab[i]
            let keyName = position.client + ' [↧' + (position.dataSortie != undefined && position.dataSortie.length ? position.dataSortie.length : 0) + '][↥' + (position.dataStock != undefined && position.dataStock.length ? position.dataStock.length : 0) + ']'
                // console.log("keyName", keyName);
            let marker = L.marker([position.posX, position.posY], { icon: greenIcon }, {
                draggable: false,
                autoPan: false,
            }).bindTooltip(keyName, {
                permanent: true,
                // direction: "right",
            });
            marker.addTo(map.value);
        }
    }

    const getClientsPosition = computed(function() {
        return Object.values(gpsPositionByClient.value)
    })

    const getClientsAllPath = computed(function() {
        let getClientsAllPath = []
        for (let i = 0; i < getClientsPosition.value.length; i++) {
            for (let a = 0; a < getClientsPosition.value.length; a++) {
                if (getClientsPosition.value[i].client != getClientsPosition.value[a].client)
                    getClientsAllPath.push({
                        id: getClientsPosition.value[i].id + '_' + getClientsPosition.value[a].id,
                        idFrom: getClientsPosition.value[i].id,
                        idTo: getClientsPosition.value[a].id,
                        clients: [getClientsPosition.value[i].client, getClientsPosition.value[a].client]
                    })
            }
        }
        return getClientsAllPath
    })

    const getItineraireTotalSummary = computed(function() {
        let totalDistance = 0;
        let totalTime = 0;
        let totalMinute = 0
        let totalPoid = [];
        if (itineraireOrder.value.data == undefined || itineraireOrder.value.data.length == 0 ||
            itineraireDataSaved.value[itineraireOrder.value.data[0].key][0] == undefined)
            return { totalDistance, totalTime, totalPoid, totalMinute }
        for (let i = 0; i < itineraireOrder.value.data.length; i++) {
            totalDistance += itineraireDataSaved.value[itineraireOrder.value.data[i].key][0].summary.totalDistance;
            totalTime += itineraireDataSaved.value[itineraireOrder.value.data[i].key][0].summary.totalTime;
            totalMinute += itineraireDataSaved.value[itineraireOrder.value.data[i].key][0].summary.totalTime;
            totalPoid.push(parseFloat(itineraireOrder.value.data[i].poid))
        }
        totalMinute = totalMinute / 60

        totalDistance = (totalDistance / 1000).toFixed(0) + ' Km';
        if (totalTime > 3600) {
            let hours = totalTime / 3600
            let min = (hours % 1) * 60

            totalTime = Math.trunc(hours) + "H " + min.toFixed(0) + "Min"
        } else
            totalTime = (totalTime / 60).toFixed(0) + ' Min';
        return { totalDistance, totalTime, totalPoid, totalMinute }
    })

    const showFullPath = function() {
        clearMap()
        let dataUsed = _.cloneDeep(itineraireOrder.value.data)
        let gpsPositionByClientTab = Object.values(gpsPositionByClient.value)

        let greenIcon = L.icon({
            iconUrl: 'map.png',
            // shadowUrl: 'map.png',
            iconSize: [50, 50], // size of the icon
            shadowSize: [50, 64], // size of the shadow
            iconAnchor: [25, 40], // point of the icon which will correspond to marker's location
            shadowAnchor: [4, 62], // the same for the shadow
            popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
        });


        for (let i = 0; i < dataUsed.length; i++) {
            let GPS = itineraireDataSaved.value[dataUsed[i].key][0]

            let position = gpsPositionByClientTab.find((val) => val.id == dataUsed[i].idFrom)
            let marker = L.marker([position.posX, position.posY], { icon: greenIcon }, {
                draggable: false,
                autoPan: false,
            }).bindTooltip(position.client, {
                permanent: true,
            });

            if (i + 1 < dataUsed.length) {
                let positionlaste = gpsPositionByClientTab.find((val) => val.id == dataUsed[i].idTo)
                let markerlaste = L.marker([positionlaste.posX, positionlaste.posY], { icon: greenIcon }, {
                    draggable: false,
                    autoPan: false,
                }).bindTooltip(positionlaste.client, {
                    permanent: true,
                });
                markerlaste.addTo(map.value);
                markerListe.value.push(markerlaste)
            }

            GPS.itineraire.addTo(map.value);
            marker.addTo(map.value);
            markerListe.value.push(marker)
            itineraireTab.value.push(GPS.itineraire);
        }


        // console.log("gpsPositionByClientTab", gpsPositionByClientTab);
        // for (let i = 0; i < gpsPositionByClientTab.length; i++) {
        //     let position = gpsPositionByClientTab[i]
        //     let marker = L.marker([position.posX, position.posY], { icon: greenIcon }, {
        //         draggable: false,
        //         autoPan: false,
        //     }).bindTooltip(position.client, {
        //         permanent: true,
        //         // direction: "right",
        //     });
        //     markerListe.value.push(marker)
        //     marker.addTo(map.value);
        // }
        clear_icon()
    }

    const clearMap = function() {
        if (itineraireTab.value.length != 0) {
            for (let i = 0; i < itineraireTab.value.length; i++) {
                map.value.removeLayer(itineraireTab.value[i]);
                map.value.removeControl(itineraireTab.value[i]);
            }
            for (let i = 0; i < markerListe.value.length; i++) {
                map.value.removeLayer(markerListe.value[i]);
                map.value.removeControl(markerListe.value[i]);
            }
            itineraireTab.value = [];
            markerListe.value = []
        }
    }

    const validItineraire = function() {
        if (itineraireValidTab.value == undefined) {
            itineraireValidTab.value = {
                name: "",
                start: moment().format(),
                time: getItineraireTotalSummary.value.totalTime,
                distance: getItineraireTotalSummary.value.totalDistance,
                poid: getItineraireTotalSummary.value.totalPoid,
                minute: getItineraireTotalSummary.value.totalMinute,
                driver: "",
                // type: "",
                // itineraire: itineraireOrder.value.data.map((val) => val[0].key),
                // itineraire: itineraireOrder.value.data,
            }
            itineraireOrderLastVue.value = itineraireOrder.value.data
        } else {
            itineraireValidTab.value.time = getItineraireTotalSummary.value.totalTime
            itineraireValidTab.value.distance = getItineraireTotalSummary.value.totalDistance
            itineraireValidTab.value.poid = getItineraireTotalSummary.value.totalPoid
            itineraireValidTab.value.minute = getItineraireTotalSummary.value.totalMinute
            itineraireOrderLastVue.value = itineraireOrder.value.data

            // itineraireValidTab.value.itineraire = itineraireOrder.value.data
            // itineraireValidTab.value.itineraire = itineraireOrder.value.data.map((val) => val[0].key)
            // itineraireValidTab.value.itineraire = itineraireOrder.value.data.map((val) => val.GPS)
        }
        clearMap()
        itineraireOrder.value.data = []
            // itineraireOrder.value.data = itineraireOrderLastVue.value
    }

    const reloadIteneraire = function() {
        let data = _.cloneDeep(itineraireOrderLastVue.value)
        itineraireOrder.value.data = []

        for (let i = 0; i < data.length; i++) {
            verifItineraire(data[i], i, data.length, function() {
                itineraireOrder.value.data = _.cloneDeep(data)
            })
        }
    }

    // const showItineraireOnMap = function(pos) {
    //     clearMap()
    //     for (let i = 0; i < pos.length; i++) {
    //         let itineraireList = _.cloneDeep(itineraireDataSaved.value[pos[i]]);
    //         if (itineraireList) {
    //             itineraireList[0].itineraire.addTo(map.value);
    //             itineraireTab.value.push(itineraireList[0].itineraire);
    //         }
    //     }
    // }

    const getUnknowAd = computed(function() {
        return Object.values(unknowAd.value).reduce((a, b) => a + b, 0)
    })

    function getColor() {
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += Math.floor(Math.random() * 10);
        }
        return color;
    }

    const showValidatedItineraire = function() {
        itineraireOrder.value = { 'generalInformation': {}, data: [] }
        let tmpData = []
        for (let i = 0; i < itineraireValidTab.value.itineraire.length; i++) {
            let itineraireId = itineraireValidTab.value.itineraire[i]
            let itineraire = _.cloneDeep(itineraireDataSaved.value[itineraireId])
            tmpData.push(itineraire)
        }
        itineraireOrder.value.data = tmpData

        for (let i = 0; i < itineraireValidTab.value.poid.length; i++) {
            itineraireOrder.value.data[i][0].poid = itineraireValidTab.value.poid[i]
        }

        itineraireOrder.value.generalInformation["name"] = itineraireValidTab.value.name;
        itineraireOrder.value.generalInformation["driver"] = itineraireValidTab.value.driver;
        itineraireOrder.value.generalInformation["start"] = itineraireValidTab.value.start;
        // itineraireOrder.value.generalInformation["type"] = itineraireValidTab.value.type;
        showFullPath();
    }

    const clear_icon = function() {
        let test = document.getElementsByClassName('leaflet-marker-icon')
        for (let i = 0; i < test.length; i++) {
            if (test[i].src.includes('marker-icon.png')) {
                // test[i].style.border = 'solid 1px red'
                test[i].style.display = 'none'
            }
        }
    }

    const verifItineraire = function(position, idx, max, callback) {
        let key = position.id;
        let color = getColor()
        let from = gpsPositionByClient.value[position.idFrom]
        let to = gpsPositionByClient.value[position.idTo]

        if (itinerairePathSaved.value[key] === undefined) {
            itineraire.value = L.Routing.control({
                waypoints: [
                    L.latLng(parseFloat(from.posX), parseFloat(from.posY)),
                    L.latLng(parseFloat(to.posX), parseFloat(to.posY)),
                ],
                lineOptions: {
                    styles: [{
                        color: color,
                        opacity: 1,
                        weight: 4
                    }]
                },
                routeWhileDragging: false,
                addWaypoints: false,
                extendToWaypoints: false,
                draggableWaypoints: false,
            });

            itinerairePathSaved.value[key] = itineraire.value;
            itineraire.value.addTo(map.value);
            itineraireTab.value.push(itineraire.value);

            itineraire.value.on("routesfound", function(e) {
                if (itineraireDataSaved.value[key] == undefined) {
                    let mapsData = e.routes.map((val) => ({
                        agency: [from.client, to.client],
                        name: val.name,
                        summary: val.summary,
                        key: key,
                        color: color,
                        itineraire: itinerairePathSaved.value[key],
                        poid: 0,
                        driver: undefined,
                        start: undefined,
                        // type: undefined,
                    }));
                    itineraireDataSaved.value[key] = mapsData
                    position['idItineraire'] = uuidv4()
                    position['color'] = color
                    position['key'] = key
                    if (position['matriculeListeSortie'] == undefined)
                        position['matriculeListeSortie'] = []
                    if (position['matriculeListeStock'] == undefined)
                        position['matriculeListeStock'] = []
                    itineraireOrder.value.data.push(position);
                    clearMap()
                    clear_icon()
                }
                if (idx != undefined && max != undefined && idx == max)
                    callback()
            });
        } else {
            position['idItineraire'] = uuidv4()
            position['key'] = key
            position['color'] = color
            if (position['matriculeListeSortie'] == undefined)
                position['matriculeListeSortie'] = []
            if (position['matriculeListeStock'] == undefined)
                position['matriculeListeStock'] = []
            itineraireOrder.value.data.push(position);
            clearMap()
            clear_icon()
            if (idx != undefined && max != undefined && idx == max)
                callback()
        }
    }

    return {
        map,
        position,
        // getItineraireDataSavedSorted,
        itineraireDataSaved,
        itineraireOrder,
        agencyFilter,
        getItineraireTotalSummary,
        itineraireValidTab,
        driversListe,
        typeListe,
        showFullPath,
        clearMap,
        validItineraire,
        showValidatedItineraire,
        eventsColor,
        getUnknowAd,
        agencySelected,
        getClientsPosition,
        getClientsAllPath,
        verifItineraire,
        gpsPositionByClient,
        itineraireOrderLastVue,
        reloadIteneraire
    };
}