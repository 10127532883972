import axios from "axios";
var CryptoJS = require("crypto-js");

class DataApi {
    constructor() {
        this.URL = "https://" + process.env.VUE_APP_MAIN_URL + "/api/sav-gestion";
        this.cryptageKey = "2687da56-4181-48a6-bec4-c526727423f8"
    }

    encrypt(datas) {
        return { data: CryptoJS.AES.encrypt(JSON.stringify(datas), this.cryptageKey).toString() }
    }

    decrypt(data) {
        var bytes = CryptoJS.AES.decrypt(data, this.cryptageKey);
        var originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return originalData
    }

    get_clients(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_clients', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_parc(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_parc', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_stock(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_stock', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_or_liste_sav2(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_or_liste_sav2', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_or_liste_sav(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_or_liste_sav', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_techniciens(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_techniciens', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_agences(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_agences', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    creat_new_or(oRdata, callback) {
        let datas = { oRdata }
        axios.post(this.URL + '/v1/creat_new_or', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    edit_or(oRdata, callback) {
        let datas = { oRdata }
        axios.post(this.URL + '/v1/edit_or', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    delete_old_moteur_by_link(link, callback) {
        let datas = { link }
        axios.post(this.URL + '/v1/delete_old_moteur_by_link', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    creat_new_moteur_2_line(motorData, callback) {
        let datas = { motorData }
        axios.post(this.URL + '/v1/creat_new_moteur_2_line', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    creat_new_moteur_1_line(motorData, callback) {
        let datas = { motorData }
        axios.post(this.URL + '/v1/creat_new_moteur_1_line', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_or_1_Liste_byLink(link, callback) {
        let datas = { link }
        axios.post(this.URL + '/v1/get_or_1_Liste_byLink', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_or_2_Liste_byLink(link, callback) {
        let datas = { link }
        axios.post(this.URL + '/v1/get_or_2_Liste_byLink', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    delete_or_by_id(id, callback) {
        let datas = { id }
        axios.post(this.URL + '/v1/delete_or_by_id', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }
}

export default new DataApi()