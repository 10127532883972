<template>
  <div style="width:100%" align="center">
    <v-card max-width="800" class="pa-2" color="#ECEFF1" style="font-weight: 600;overflow: auto; height: 90vh;" align="center">
      <v-col>
        <v-row justify="center" align="center" class="mb-3">
          <v-col  align="left" cols="6" class="px-0">
            <div v-if="agency" class="pa-2" style="background-color: white; border: solid 1px lightgrey" align="center">
              <div v-for="key in getAgencyDataToShow" :key="key">
                <div v-if="key == 'ag_cp'">
                  {{ agency[key] }}
                  {{ agency["ag_ville"] }}
                </div>
                <div v-else>
                  {{ agency[key] }}
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="6" align="center">
            <div class="pa-2" v-if="contratData" style="
              font-weight: 800;
              border: solid 1px grey;
              border-radius: 5px;
              background-color: white;
              font-size: 15px;
            ">
              <div v-for="(keysTab, idx) in getContraDataToShowOnTheTop" :key="idx">
                <div v-if="keysTab.length == 1">
                  {{ contratData[keysTab[0]] }}
                </div>
                <div v-else>
                  {{ contratData[keysTab[0]] }}
                  {{ contratData[keysTab[1]] }}
                </div>
                <!-- {{ key }} : {{ contratData[key] }} -->
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0 mb-2" style="border: solid 1px lightgrey; background-color: white"
            align="center">
            <h2 class="ma-0 darkBackground">Information Location</h2>
          </v-col>
          <v-col class="" style="border: solid 1px lightgrey">
            <v-row class="">
              <v-col cols="6" class="pr-0" style="border-right: solid 1px lightgrey; background-color: white">
                <v-card align="left" v-if="contratData" variant="text" height="100%" class="pa-2">
                  Dossier suivie par : {{ contratData["CL_SUIVI_PAR"] }} <br />
                  Référence à rappeler : {{ contratData["CL_NUM_CONTRAT"] }}
                </v-card>
              </v-col>
              <v-col cols="6" class="pl-0" style="background-color: white">
                <v-card align="left" v-if="contratData" variant="text" height="100%" class="pa-2">
                  Compte Client : {{ contratData["CL_CLIENT_COMPTE"] }} <br />
                  Réf. Cde Client : {{ contratData["CL_REF_COMMANDE"] }} <br />
                  Référence Chantier : {{ contratData["CL_REF_CHANTIER"] }} <br />
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="mt-1 px-0">
            <v-table style="border: solid 1px lightgrey">
              <thead>
                <tr>
                  <th class="form-div" style="border-right: solid 1px lightgrey">
                    REF
                  </th>
                  <th class="form-div" style="border-right: solid 1px lightgrey">
                    Description
                  </th>
                  <th class="form-div" style="border-right: solid 1px lightgrey">
                    Sous famille
                  </th>
                  <th class="form-div" style="border-right: solid 1px lightgrey">
                    Marque
                  </th>
                  <th class="form-div">Numéro de série</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="form-div" style="border-right: solid 1px lightgrey">
                    {{ eventData["CLD_MATRICULE"] }}
                  </td>
                  <td class="form-div" style="border-right: solid 1px lightgrey">
                    {{ eventData["CLD_DESCRIPTION"] }}
                  </td>
                  <td class="form-div" style="border-right: solid 1px lightgrey">
                    {{ eventData["CLD_SOUS_FAMILLE"] }}
                  </td>
                  <td class="form-div" style="border-right: solid 1px lightgrey">
                    {{ eventData["CLD_MARQUE"] }}
                  </td>
                  <td class="form-div">
                    {{ eventData["CLD_NUMERO_SERIE"] }}
                  </td>
                </tr>
              </tbody>
            </v-table>
            <br />
            <v-table style="width: 100%; border: solid 1px lightgrey">
              <thead>
                <tr class="darkBackground">
                  <th class="form-div" style="border-right: solid 1px lightgrey">
                    Date départdd
                  </th>
                  <th class="form-div">Heure départ</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="form-div" style="border-right: solid 1px lightgrey">
                    {{ convertToFrDateFomat(eventData["CLD_DATE_DEPART"]) }}
                  </td>
                  <td class="form-div">
                    {{ eventData["CLD_HEURE_DEPART"] }}
                  </td>
                </tr>
              </tbody>
            </v-table>
            <br />
            <v-table style="width: 100%; border: solid 1px lightgrey">
              <thead>
                <tr class="darkBackground">
                  <th class="form-div" style="border-right: solid 1px lightgrey">
                    Date retour
                  </th>
                  <th class="form-div">Heure retour</th>
                </tr>
              </thead>
              <tbody>
                <tr style="width: 100%">
                  <td class="form-div" style="border-right: solid 1px lightgrey">
                    {{ convertToFrDateFomat(eventData["CLD_DATE_RETOUR"]) }}
                  </td>
                  <td class="form-div">
                    {{ eventData["CLD_HEURE_RETOUR"] }}
                  </td>
                </tr>
              </tbody>
            </v-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div style="font-size: 13px">
              <!-- <v-divider class=""></v-divider> -->
              <v-col v-if="societe" align="center">
                {{ societe["RS"] }} - {{ societe["TypeEntreprise"] }} au capital
                de {{ societe["Capital"] }} € - SIRET
                {{ societe["NumeroSIRET"] }} - APE : {{ societe["CodeAPE"] }} -
                TVA : {{ societe["TVAIntracommunautaire"] }} <br />
                <div v-if="agency">
                  {{ agency["ag_nom"] }} - {{ agency["ag_adresse"] }}
                  {{ agency["ag_cp"] }} {{ agency["ag_ville"] }} - Tél :
                  {{ agency["ag_telephone"] }}
                </div>
              </v-col>
            </div>
            <v-divider class=""></v-divider>
            <v-col align="center" cols="12">
              <v-btn variant="flat" color="#EF5350" style="color: white; width: 100%" @click="closeModal">Fermer
              </v-btn>
            </v-col>
            <div align="center" v-if="false">
              <v-btn color="primary" :href="getAccenUrl">
                Ouvrir dans ACCEN 55
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import engine from "@/core/index";

export default {
  data() {
    return {
      // logoSrc: "data:image/gif;base64,",
      contratData: undefined,
      agency: undefined,
      client: undefined,
      societe: undefined,
      loading: true,
      eventData: {},
    };
  },
  props: {
    data: {},
  },
  mounted() {
    let that = this;
    this.eventData = JSON.parse(
      JSON.stringify(this.data).replaceAll("R_", "CLD_")
    );
    engine.get_contrat_from_num_contrat(
      that.eventData.CLD_NUM_CONTRAT,
      function (data) {
        if (data.code == 0) {
          that.contratData = data.data.data.map((val) => val.fieldData);
          that.contratData = that.contratData[0];
        }
        that.loading = false;
      }
    );
    engine.get_clients(function (data) {
      if (data.code == 0) {
        let result = data.data.data.find(
          (val) => val.fieldData.CL_LIEN == that.eventData.CLD_LIEN
        );
        if (result) {
          that.client = result.fieldData;
          engine.get_societe_by_id_soft(that.client.CL_ID_SOCIETE, function (data) {
            if (data.code == 0) {
              that.societe = data.data.data[0].fieldData;
            }
          });
        }
      }
    });
    engine.get_agency_soft(function (data) {
      if (data.code == 0) {
        let result = data.data.data.find(
          (val) => val.fieldData.ID_Agence == that.eventData.CLD_ID_AGENCE
        );
        if (result) {
          that.agency = result.fieldData;
          // that.logoSrc = String(
          //   that.logoSrc + JSON.parse(that.agency["Logo_JSON"]).Logo_Base64
          // );
        }
      }
    });
  },
  computed: {
    getAccenUrl() {
      // return (
      //   "fmp://demo-accen.dyndns.org/Contrat%20Loc.fmp12?script=affiche_contrat&param=" +
      //   this.eventData["CLD_NUM_CONTRAT"]
      // );
      return (
        "fmp://demo-accen.dyndns.org/Contrat%20Loc.fmp12?script=affiche_contrat&param=" +
        this.eventData["CLD_NUM_CONTRAT"]
      );
    },
    getContraDataToShowOnTheTop() {
      let keys = [
        ["CL_CLIENT_RAISON_SOCIALE"],
        ["CL_CLIENT_ADRESSE", "CL_CLIENT_COMP_ADRESSE"],
        ["CL_CLIENT_VILLE", "CL_CLIENT_CODEPOSTAL"],
      ];
      return keys;
    },
    getAgencyDataToShow() {
      let keys = [
        "ag_adresse",
        "ag_cp",
        // "ag_ville",
        "ag_telephone",
        "ag_courriel",
        "ag_siteweb",
      ];
      return keys;
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeStatesModal");
    },
    convertToFrDateFomat(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>

<style>

</style>