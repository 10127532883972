<template>
  <div>
    <v-col class="pa-1 pt-0">
      <v-card class="pa-2">
        <div align="center" class="py-1" style="  text-transform: uppercase;font-weight:bold">
          Séléctionner l'étendu de recherche par date
        </div>
        <v-divider class="mb-2"></v-divider>
        <v-row class="px-2">
          <v-col v-for="(elem, i) in btnListe" :key="i" class="px-1">
            <v-btn style="width: 100%;font-weight: bold;" :color="selectedbtnIndex == i ? 'primary' : undefined"
              class="px-1" @click="setDate(elem, i)" :variant="selectedbtnIndex == i ? undefined : 'tonal'">
              {{ elem.label }}
            </v-btn>
          </v-col>
        </v-row>
        <v-col class="px-0 pt-2 pb-0">
          <v-row justify="center" align="center" v-if="selectedbtnIndex != 4" class="px-2 pb-2 mt-0">
            <v-divider class="mx-1"></v-divider>
            <v-col align="center" class="pb-1">
              <v-chip style="padding: 0px 30px">
                Date de départ : {{ formatDate(date.from, 'DD/MM/YYYY') }}
              </v-chip>
            </v-col>
            <v-divider vertical class="mt-2"></v-divider>
            <v-col align="center" class="pb-1">
              <v-chip style="padding: 0px 30px">
                Date de fin : {{ formatDate(date.to, 'DD/MM/YYYY') }}
              </v-chip>
            </v-col>
          </v-row>
          <v-card v-else class="pa-2" style="background-color: #E0E0E0;" variant="text">
            <v-col>
              <v-row>
                <v-col align="center" v-if="selectedbtnIndex == 4" class="py-0 pl-1 pr-2">
                  Date de départ
                  <datepicker style="color:white" v-if="selectedbtnIndex == 4" v-model="date.from" locale="fr"
                    :enable-time-picker="false" format="dd/MM/yyyy" auto-apply model-type="MM/dd/yyyy"
                    @update:model-value="setNewDataChart" :clearable="false"></datepicker>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col align="center" v-if="selectedbtnIndex == 4" class="py-0 pr-1 pl-2">
                  Date de fin
                  <datepicker v-if="selectedbtnIndex == 4" v-model="date.to" locale="fr" :enable-time-picker="false"
                    format="dd/MM/yyyy" auto-apply model-type="MM/dd/yyyy" @update:model-value="setNewDataChart"
                    :clearable="false"></datepicker>
                </v-col>
              </v-row>

            </v-col>
          </v-card>
        </v-col>
      </v-card>
    </v-col>
    <v-col class="pt-2 my-1 px-3" style="overflow:auto"
      :style="{ height: selectedbtnIndex != 4 ? '77.3vh' : '74vh' }">
      <v-row id="captureMateriel" v-if="statistiqueCreatedListe.length != 0">
        <v-col v-for="(elem, i) in statistiqueCreatedListe" :key="i" class="pa-1" cols="12" sm="12" md="6">
          <v-card class="pa-2" variant="text" style="background-color:white;border:none">
            <v-row justify="center">
            </v-row>
            <v-col class="pt-6">
              <component :is="elem.STATS_TYPE" :chartData="elem.data" :options="getOptions(elem)">
              </component>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
      <div v-else style="margin-top: 30vh;" align="center">
        <v-progress-circular v-if="dataIsValide" :size="200" :width="7" color="#5C6BC0" bg-color="lightgrey"
          indeterminate>Chargement</v-progress-circular>
        <v-col v-else cols="10">
          <v-card class="pa-3">
            Aucune donnée disponible pour les dates sélectionnées
          </v-card>
        </v-col>
      </div>
    </v-col>
    <!-- <v-col class="pa-1" align="center">
      <v-btn COLOR="#7986CB" @click="save_html_into_pdf" style="width:100%;color:black" density="compact">
        Exporter
      </v-btn>
    </v-col> -->
  </div>
</template>

<script>
import engine from "../core/DataApi";
import Datepicker from '@vuepic/vue-datepicker';
import moment from "moment";
import { BarChart, DoughnutChart, LineChart } from "vue-chart-3";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default {
  data() {
    return {
      dataIsValide: true,
      selectedbtnIndex: 3,
      btnListe: [
        { label: '12 Mois', subvalue: 12, subType: 'M' },
        { label: '6 Mois', subvalue: 6, subType: 'M' },
        { label: '3 Mois', subvalue: 3, subType: 'M' },
        { label: '1 Mois', subvalue: 1, subType: 'M' },
        { label: 'Personnaliser', subvalue: undefined, subType: undefined }],
      date: { from: undefined, to: undefined },
      statistiqueCreatedListe: [],
      statistiqueListe: undefined
    };
  },
  components: { BarChart, DoughnutChart, LineChart, Datepicker },
  mounted() {
    this.date.from = moment().subtract(1 * 30, 'd').format('MM/DD/YYYY')
    this.date.to = moment().format('MM/DD/YYYY')
    this.getStatistiqueListe()
  },
  methods: {
    formatDate(date, format) {
      return moment(date).format(format)
    },
    setDate(elem, i) {
      this.selectedbtnIndex = i
      if (elem.subvalue && elem.subType) {
        this.date.from = moment().subtract(elem.subvalue, elem.subType).format('MM/DD/YYYY')
        this.date.to = moment().format('MM/DD/YYYY')
        this.initAllCharts()
      }
    },
    getOptions(elem) {
      let option = {}
      if (elem.IS_HORIZONTALE == 1) {
        option = JSON.parse(JSON.stringify(this.getChartHorizontalOptions))

      } else {
        option = JSON.parse(JSON.stringify(this.getChartOptions))
      }

      if (elem.STATS_TYPE == "DoughnutChart") {
        let donutsOption = {
          title: {
            display: true,
            text: elem.LABEL
          },
          datalabels: {
            display: true,
            formatter: (val, ctx) => {
              const label = ctx.chart.data.labels[ctx.dataIndex];
              if (typeof parseFloat(val) === "number")
                return label + " : " + parseFloat(val).toFixed(2);
              else
                return label + " : " + val;
            },
            color: '#fff',
            borderRadius: '5'
          }
        }
        option.plugins = Object.assign({}, donutsOption, option.plugins);
        option.plugins['tooltip'] = {
          enabled: true,
          intersect: false,
          mode: 'point',
        }
        option.interaction = {
          mode: 'point'
        }

      }
      if (elem.IS_COMPLEX == '1' || elem.STATS_TYPE == "DoughnutChart") {
        let complexOption = {
          title: {
            display: true,
            text: elem.LABEL
          }
        }
        option.plugins = Object.assign({}, option.plugins, complexOption);
      }
      return option
    },
    getComplexChartData(finalData, bddDataAdded, colors, params) {
      let yAxisData = []
      let yAxisDataCtn = {}
      let xAxisData = Object.keys(bddDataAdded)
      finalData.labels = xAxisData

      for (const [key, value] of Object.entries(bddDataAdded)) {
        for (const [key2, value2] of Object.entries(value)) {
          if (yAxisDataCtn[key2] && yAxisDataCtn[key2][key]) {
            yAxisDataCtn[key2][key] += value2
          } else {
            if (yAxisDataCtn[key2] == undefined)
              yAxisDataCtn[key2] = {}
            yAxisDataCtn[key2][key] = value2
          }
          if (!yAxisData.includes(key2))
            yAxisData.push(key2)
        }
      }

      for (let i = 0; i < yAxisData.length; i++) {
        let tmpData = new Array(xAxisData.length).fill(0)
        for (let a = 0; a < tmpData.length; a++) {
          let key = xAxisData[a]
          let findedRes = yAxisDataCtn[yAxisData[i]][key]
          tmpData[a] = findedRes == undefined ? 0 : findedRes
        }
        let dataWithOption = {
          borderWidth: 1,
          hoverBorderColor: "red",
          scaleStepWidth: 1,
          backgroundColor: colors,
          datalabels: {
            formatter: (val) => {
              if (typeof parseFloat(val) === "number")
                return parseFloat(val).toFixed(2);
              else
                return val;
            },
            COLOR: 'black',
            anchor: params.IS_HORIZONTALE == '1' ? 'center' : 'center',
            align: params.IS_HORIZONTALE == '1' ? 'end' : 'end',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 11
                }
              },
            },
            color: 'black',
            // backgroundColor: '#404040',
            // borderRadius: '5'
          },
        }
        dataWithOption['label'] = yAxisData[i]
        dataWithOption['data'] = tmpData
        dataWithOption['backgroundColor'] = i % 2 == 0 ? colors[0] : colors[1]
        finalData.datasets.push(dataWithOption)
      }
      return finalData
    },
    getStatistiqueListe() {
      let that = this
      engine.getStatistiqueListe(function (data) {
        if (data.code == 0) {
          that.statistiqueListe = data.data.data.map((val) => val.fieldData);
          that.initAllCharts()
        }
      })
    },
    save_html_into_pdf() {
      window.scrollTo(0, 0);
      // let that = this;

      let data = document.querySelector("#captureMateriel");

      // data.style.width = "1000px";
      data.style.backgroundColor = "white";

      // data.style.border = "solid 1px lightgrey";
      let html2canvasOptions = {
        allowTaint: true,
        removeContainer: true,
        backgroundColor: null,
        imageTimeout: 15000,
        logging: true,
        scale: 2,
        useCORS: true,
        processData: false,
        contentType: false,
        // windowWidth: 1600,
        // windowHeight: 1600,
      };
      html2canvas(data, html2canvasOptions).then(async (canvas) => {
        // optionsTab.style.display = 'block'
        var imgData = await canvas.toDataURL("image/png");
        var imgWidth = 204;
        var imgHeight = 150;
        var position = 3;
        var pageHeight = 300
        var heightLeft = imgHeight - pageHeight

        var doc = new jsPDF("p", "mm");
        doc.addImage(
          imgData,
          "PNG",
          3,
          position,
          imgWidth,
          imgHeight,
          undefined,
          "FAST"
        );

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight + 10;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 3, position, imgWidth, imgHeight, undefined, 'FAST');
          heightLeft -= pageHeight;
        }

        var link = document.createElement('a');
        link.href = doc.output("datauristring");
        link.download = 'export_statistique.pdf';
        link.dispatchEvent(new MouseEvent('click'));
        data.style.backgroundColor = "transparent";
      });
    },
    setNewDataChart() {
      this.initAllCharts()
    },
    initAllCharts() {
      let that = this
      this.statistiqueCreatedListe = []
      let formData = [{ 'Mot_Date': this.date.from + "..." + this.date.to }];
      that.dataIsValide = true
      engine.getStatData(formData, function (data) {
        if (data.code == 0) {
          let bddData = data.data.data.map((val) => val.fieldData);
          for (let i = 0; i < that.statistiqueListe.length; i++) {
            that.addStat(that.statistiqueListe[i], bddData)
          }
        } else {
          that.dataIsValide = false
        }
      })
    },
    addStat(data, bddData) {
      this.statistiqueCreatedListe.push(data)
      this.searchStatsFilterByPrams(data, bddData)
    },
    searchStatsFilterByPrams(params, bddData) {
      let that = this
      let keys = params.STATS_KEYS.split(",")
      let bddDataAdded = {}

      for (let i = 0; i < bddData.length; i++) {
        if (params.IS_COMPLEX == '1') {
          if (bddDataAdded[bddData[i][keys[0]]] && bddDataAdded[bddData[i][keys[0]]][bddData[i][keys[1]]]) {
            bddDataAdded[bddData[i][keys[0]]][bddData[i][keys[1]]] += 1
          } else {
            if (bddDataAdded[bddData[i][keys[0]]]) {
              bddDataAdded[bddData[i][keys[0]]][bddData[i][keys[1]]] = 1
            } else {
              bddDataAdded[bddData[i][keys[0]]] = {}
              bddDataAdded[bddData[i][keys[0]]][bddData[i][keys[1]]] = 1
            }
          }
        } else {
          if (typeof bddData[i][keys[1]] === "number") {
            if (bddDataAdded[bddData[i][keys[0]]]) {
              bddDataAdded[bddData[i][keys[0]]] += bddData[i][keys[1]]
            } else {
              bddDataAdded[bddData[i][keys[0]]] = bddData[i][keys[1]]
            }
          } else {
            if (bddDataAdded[bddData[i][keys[0]]]) {
              bddDataAdded[bddData[i][keys[0]]] += 1
            } else {
              bddDataAdded[bddData[i][keys[0]]] = 1
            }
          }
        }
      }

      let colors = []
      let finalData = {
        labels: [],
        datasets: []
      }

      if (params.COLOR.includes(','))
        colors = params.COLOR.split(",")
      else colors = [params.COLOR]

      var options = {
        borderWidth: 1,
        hoverBorderColor: "red",
        scaleStepWidth: 1,
        label: params.LABEL,
        backgroundColor: colors,
        datalabels: {
          formatter: (val) => {
            if (typeof parseFloat(val) === "number")
              return parseFloat(val).toFixed(2);
            else
              return val;
          },
          COLOR: 'black',
          anchor: params.IS_HORIZONTALE == '1' ? 'center' : 'center',
          align: params.IS_HORIZONTALE == '1' ? 'end' : 'end',
          labels: {
            title: {
              font: {
                weight: 'bold',
                size: 11
              }
            },
          },
          color: 'black',
        },
      }

      if (params.IS_COMPLEX != '1') {
        finalData['datasets'] = [
          options
        ]
      }

      if (params.IS_COMPLEX == '1') {
        finalData = that.getComplexChartData(finalData, bddDataAdded, colors, params)
      }
      else {
        let datasets = []
        for (const [key, value] of Object.entries(bddDataAdded)) {
          finalData.labels.push(key)
          datasets.push(value)
        }
        finalData.datasets[0]['data'] = datasets
      }

      params.data = finalData
    }
    // });
    // },
  },
  computed: {
    getChartHorizontalOptions() {
      return {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'index',
          axis: 'y'
        },
        plugins: {
          tooltip: {
            intersect: false,
            axis: 'y',
          },
          title: {
            display: 'red'
          },
          legend: {
            // position: 'bottom',
            title: {
              fontColor: 'red',
            },
            labels: {
              COLOR: '#424242',
              font: {
                size: 16,
                weight: 'bold'
              }
            }
          }
        }
      }
    },
    getChartOptions() {
      let option = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          tooltip: {
            enabled: false,
            intersect: false,
            mode: 'index',
          },
          title: {
            display: 'red'
          },
          legend: {
            // position: 'bottom',
            title: {
              fontColor: 'red',
            },
            labels: {
              COLOR: '#424242',
              font: {
                size: 16,
                weight: 'bold'
              }
            }
          }
        }
      }

      return option
    }
  },


};
</script>

<style>
@import "../style.css";
</style>