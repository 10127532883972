<template>
  <v-dialog v-model="isOrModalOpen" width="98%" max-width="900" persistent>
    <v-col>
      <v-card style="overflow: auto; max-height: 90vh;">
        <v-col>
          <div style="position:absolute;right:10px;">
            <v-btn icon="mdi-close-circle" density="small" variant="text" color="#E57373" style="font-size: 20px;"
              @click="isOrModalOpen = false">
            </v-btn>
          </div>
          <contrat-form @close="closeModal()" :itemsData="itemsData" :technicienListe="technicienListe"
            :agenceListe="agenceListe" :clientListe="clientListe" :parcListe="parcListe" :stockListe="stockListe"
            :FormData="savForm" :subFamilyListe="subFamilyListe" :familyListe="familyListe"
            :contrat_informations="contrat_informations"></contrat-form>
        </v-col>
      </v-card>
    </v-col>
  </v-dialog>
  <div>
    <v-card class="pa-1 mx-1">
      <v-col>
        <v-row align="center">
          <v-col class="">
            <h3>Liste des contrats</h3>
          </v-col>
          <v-col cols="auto">
            <v-btn variant="flat" color="rgb(92, 107, 192)" style="color:white" @click="openDialog(undefined)">Créer un
              contrat
            </v-btn>
            <v-btn icon="mdi-refresh" density="compact" style="font-size:20px" variant="text" size="40"
              @click="getContratsListe" class="ml-3"></v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-text-field hide-details v-model="search" variant="outlined" label="Recherche" clearable></v-text-field>
      </v-col>
      <v-col>
        <Vue3EasyDataTable table-class-name="customize-table" ref="dataTable" :headers="homeHeader" :items="contratListe"
          style="overflow: auto" :table-height="windowHeight * 0.7" rows-per-page-message="Ligne par colonne"
          rows-of-page-separator-message="total" rows-per-page="50" empty-message="Aucune contrat trouvé">
          <template #item-facturation="item">
            <div align=center v-if="item.facturation && item.facturation.length > 0">
              <v-icon color="#66BB6A">
                mdi-check-circle
              </v-icon>
            </div>
            <div align=center v-else>
              <v-icon color="#FFD54F">
                mdi-clock-time-eight
              </v-icon>
            </div>
          </template>
        </Vue3EasyDataTable>
      </v-col>
    </v-card>
  </div>
</template>

<script>

import Vue3EasyDataTable from "vue3-easy-data-table";
import contartHooks from '../hooks/contrat';
import ContratForm from './contrat-form.vue'

export default {
  data() {
    return {
      itemsData: undefined,
      isOrModalOpen: false,
      windowHeight: window.innerHeight,
    }
  },
  setup() {
    const { getContratsListe, contratListe, homeHeader, clientListe, parcListe, familyListe, subFamilyListe, contrat_informations } = contartHooks()

    return { getContratsListe, contratListe, homeHeader, clientListe, parcListe, familyListe, subFamilyListe, contrat_informations }
  },
  mounted() {
    this.onResize()
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight
    },
    openDialog(data) {
      this.itemsData = data
      this.isOrModalOpen = true
    }
  },
  computed: {
  },
  components: {
    ContratForm,
    Vue3EasyDataTable
  }
}
</script>

<style></style>