import axios from "axios";
var CryptoJS = require("crypto-js");

class planningTechnicien {
    constructor() {
        this.URL = "https://" + process.env.VUE_APP_MAIN_URL + "/api/planningTechnicien";
        this.cryptageKey = "2687da56-4181-48a6-bec4-c526727423f8"
    }

    encrypt(datas) {
        return { data: CryptoJS.AES.encrypt(JSON.stringify(datas), this.cryptageKey).toString() }
    }

    decrypt(data) {
        var bytes = CryptoJS.AES.decrypt(data, this.cryptageKey);
        var originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return originalData
    }

    set_technicien_available_liste_by_key(key, data, callback) {
        let datas = { key, data }
        axios.post(this.URL + '/v1/set_technicien_available_liste_by_key', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_calendar_settings(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_calendar_settings', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }


    get_technicien_available_liste(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_technicien_available_liste', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_technicien_liste(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_technicien_liste', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_event_info_by_event_link(link, callback) {
        let datas = { link }
        axios.post(this.URL + '/v1/get_event_info_by_event_link', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_old_events_liste(start, end, callback) {
        let datas = { start, end }
        axios.post(this.URL + '/v1/get_old_events_liste', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_events_liste(start, end, callback) {
        let datas = { start, end }
        axios.post(this.URL + '/v1/get_events_liste', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    set_event(id, data, callback) {
        let datas = { id, data }
        axios.post(this.URL + '/v1/set_event', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_agency(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_agency', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }


    get_agency_soft(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_agency_soft', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    login(username, password, callback) {
        let datas = { username, password }
        axios.post(this.URL + '/v1/login', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    setTechToShow(token, liste, callback) {
        let datas = { token, liste }
        axios.post(this.URL + '/v1/setTechToShow', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    getTechToShow(username, password, callback) {
        let datas = { username, password }
        axios.post(this.URL + '/v1/getTechToShow', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    setAgency(username, password, agencyId, callback) {
        if (agencyId == undefined)
            agencyId = ""
        let datas = { username, password, agencyId }
        axios.post(this.URL + '/v1/setAgency', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    getAgency(username, password, callback) {
        let datas = { username, password }
        axios.post(this.URL + '/v1/getAgency', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    getIndisponibilityType(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/getIndisponibilityType', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    save_calendar_colors(colors, callback) {
        let datas = { colors }
        axios.post(this.URL + '/v1/save_calendar_colors', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_calendar_colors(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_calendar_colors', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    save_day_to_hide(days, callback) {
        let datas = { days }
        axios.post(this.URL + '/v1/save_day_to_hide', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_day_to_hide(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_day_to_hide', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }
}

export default new planningTechnicien()