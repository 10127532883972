<template>
    <div class="sav-form">
        <v-row>
            <v-col style="color: #90A4AE;" align="center">
                <h1 style="text-transform: uppercase;">
                    Ordre d'intervention
                </h1>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12">
                <div class="stepContainer">
                    <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                        Information client
                    </h3>
                    <v-row>
                        <v-col cols="12">
                            <v-card class="pa-2" variant="flat" color="#7986CB">
                                <v-autocomplete v-model="form['client']" class="edition" :items="clientListe"
                                    :readonly="itemsData.Table == 'SAV_FICHE'"
                                    :disabled="!isEditable && itemsData.Table != 'SAV_FICHE'" item-title="SOCIETE"
                                    return-object label="Client" variant="solo" hide-details bg-color="white">
                                </v-autocomplete>
                                <v-card v-if="itemsData.Table == 'SAV_FICHE'" color="red"
                                    style="color:black;font-weight: bold;border-radius: 0px;" class="px-2 py-1">
                                    Cette Information n'est pas modifiable
                                </v-card>
                            </v-card>
                        </v-col>
                        <v-col cols="6" v-for="elem in clientDataToShowElem" :key="elem.key">
                            <v-card class="px-4 py-1" variant="text"
                                style="border: solid 1px lightgray;background-color: white;height: 100%;">
                                <div style="font-size: 12px; font-weight: normal;">
                                    {{ elem.label }}
                                </div>
                                <div style="font-size: 19px; font-weight: normal;">
                                    {{ form['client'][elem.key] }}
                                </div>
                            </v-card>
                            <!-- <v-text-field v-model="form['client']['VILLE']" readonly class="disabledFormData"
                                hide-details label="ville">
                            </v-text-field> -->
                        </v-col>
                    </v-row>
                </div>
                <v-divider class="mt-6 mx-4"></v-divider>
            </v-col>
            <v-col v-if="step >= 2">
                <div class="stepContainer">
                    <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                        Theme devis
                    </h3>
                    <v-card variant="flat" color="#7986CB" class="pa-2">
                        <v-textarea v-model="form['themeDevis']" label="Raison de l'intervention..." :disabled="!isEditable"
                            class="edition" variant="solo" hide-details></v-textarea>
                    </v-card>
                </div>
                <v-divider class="mt-6 mx-4"></v-divider>
            </v-col>
            <v-col cols="12" v-if="step >= 2" id="date">
                <div class="stepContainer">
                    <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                        Liste Date / Horaire d'intervention
                    </h3>
                    <v-row justify="center" align=center class="pb-3">
                        <v-col cols="11">
                            <v-card variant="flat" style="background-color: white;" class="pa-2"
                                v-if="slotHoraireListe.length > 0">
                                <table style="">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Début</th>
                                            <th>Fin</th>
                                            <th>Technicien</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(elem, idx) in slotHoraireListe" :key="idx">
                                            <td align="center">{{ elem['date'] }}</td>
                                            <td align="center">{{ elem['timeStart'] }}</td>
                                            <td align="center">{{ elem['timeEnd'] }}</td>
                                            <td align="center">{{ elem['technicien'].NOM_TECHNICIEN }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-card variant="flat" color="transparent" style="border:solid 8px #7986CB" class="pa-2">
                        <v-row>
                            <v-col cols="6">
                                <v-text-field v-model="slotHoraire['date']" hide-details class="edition" type="date"
                                    variant="solo" :disabled="!isEditable" label="Date d'intervention"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-select label="Technicien" class="edition" v-model="slotHoraire['technicien']"
                                    :items="technicienListe" return-object :item-title="'NOM_TECHNICIEN'" hide-details
                                    variant="solo" :disabled="!isEditable">
                                </v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="slotHoraire['timeStart']" hide-details class="edition" type="time"
                                    :disabled="!isEditable" variant="solo" label="Heure de départ"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="slotHoraire['timeEnd']" hide-details class="edition" type="time"
                                    variant="solo" :disabled="!isEditable" label="Heure de fin"></v-text-field>
                            </v-col>
                            <v-col align="center">
                                <v-btn @click="addSlotHoraire">
                                    Ajouter une tranche horaire
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </div>
                <v-divider class="mt-6 mx-4"></v-divider>
            </v-col>
            <v-col cols="12" v-if="step >= 2" id="machine">
                <div class="stepContainer">
                    <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                        Information machine
                    </h3>
                    <v-col cols="12">
                        <v-card variant="flat" color="#7986CB" class="pa-2">
                            <v-combobox class="edition" v-model="form['materiel']" return-object item-title="MATRICULE"
                                :readonly="itemsData.Table == 'SAV_FICHE'" :items="parcListe" hide-details label="Matricule"
                                variant="solo" :disabled="!isEditable">
                            </v-combobox>
                            <v-card v-if="itemsData.Table == 'SAV_FICHE'" color="red"
                                style="color:black;font-weight: bold;border-radius: 0px;" class="px-2 py-1">
                                Cette Information n'est pas modifiable
                            </v-card>
                        </v-card>
                    </v-col>
                    <v-col v-if="form['materiel']">
                        <v-row>
                            <!-- <v-card class="px-4 py-1" variant="text" style="border: solid 1px lightgray;"> -->
                            <v-col cols="6" v-for="  elem   in   materielElemToShow  " :key="elem.key">
                                <v-card class="px-4 py-1" variant="flat" style="border: solid 1px lightgray;">
                                    <div style="font-size: 12px; font-weight: normal;">
                                        {{ elem.label }}
                                    </div>
                                    <div style="font-size: 19px; font-weight: normal;">
                                        {{ form['materiel'][elem.key] }}
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols="6">
                                <v-card variant="flat" color="#7986CB" class="pa-2">
                                    <v-text-field class="edition" v-model="form['Kilometrage']" hide-details
                                        label="Trajet en KM" variant="solo" :disabled="!isEditable">
                                    </v-text-field>
                                </v-card>
                            </v-col>
                            <v-col cols="6">
                                <v-card variant="flat" color="#7986CB" class="pa-2">
                                    <v-text-field class="edition" v-model="form['Horametre']" hide-details
                                        label="Compteur horaire" variant="solo" :disabled="!isEditable">
                                    </v-text-field>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </div>
                <v-divider class="mt-6 mx-4"></v-divider>
            </v-col>
            <v-col cols="12" v-if="step >= 3" id="techn">
                <div class="stepContainer">
                    <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                        Technicien(s)
                    </h3>
                    <v-card variant="flat" color="#7986CB" class="pa-2">
                        <v-select class="edition" v-model="form['technicien']" :items="technicienListe" return-object
                            :item-title="'NOM_TECHNICIEN'" hide-details variant="solo" :disabled="!isEditable">
                        </v-select>
                    </v-card>
                </div>
                <v-divider class="mt-6 mx-4"></v-divider>
            </v-col>
            <v-col cols="12" v-if="step >= 4">
                <div class="stepContainer">
                    <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                        Accessoire(s)
                    </h3>
                    <v-row align=center class="mb-2">
                        <v-col cols="">
                            <v-card variant="flat" color="#7986CB" class="pa-2">
                                <v-combobox class="edition" v-model="selectedAccessoire" :items="stockListe"
                                    item-title="PIECE_DESIGNATION" hide-details label="Sélectionner un accessoire"
                                    variant="solo" :disabled="!isEditable"></v-combobox>
                            </v-card>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn @click="addAcc" :disabled="!isEditable">Ajouter</v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <Vue3EasyDataTable hide-footer :headers="headers" :items="items" header-text-direction="center"
                                body-text-direction="center" @click-row="clickRow" empty-message="Aucun accesoire ajouté">
                                <template #item-qte="item">
                                    <v-col class="pa-0">
                                        <v-text-field v-model="items.find((val) => val.id == item.id).qte" type="number"
                                            hide-details density="compact" :disabled="!isEditable"></v-text-field>
                                    </v-col>
                                </template>
                            </Vue3EasyDataTable>
                        </v-col>
                        <v-col align=center cols="12" v-if="isEditable">
                            <v-btn @click="validForm" color="#81C784" style="color:white">
                                Valider l'intervention
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>


import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import { uuid } from 'vue-uuid'
import engine from '../core/DataApi'
import moment from 'moment'
import { ref } from "@vue/reactivity";
import { watch } from "vue";

export default {
    props: {
        technicienListe: {},
        agenceListe: {},
        FormData: {},
        clientListe: {},
        parcListe: {},
        stockListe: {},
        itemsData: {}
    },
    setup() {
        const form = ref({})
        const step = ref(1)
        const slotHoraire = ref({})
        const slotHoraireListe = ref([])
        const clientDataToShowElem = ref([
            { label: 'Adresse', key: 'ADRESSE' },
            { label: 'Téléphone', key: 'TELEPHONE' },
            { label: 'Ville', key: 'VILLE_LIV' },
            { label: 'Code postal', key: 'CODE_POSTAL_LIV' }
        ])

        watch(() => form, (currentValue) => {
            let keys = [
                { key: ['technicien'], step: 4, id: "techn" },
                { key: ['materiel'], step: 3, id: "techn" },
                // { key: ['date', 'timeStart', 'timeEnd'], step: 3, id: "machine" },
                { key: ['client'], step: 2, id: "date" },
            ]

            for (let i = 0; i < keys.length; i++) {
                if (keys[i].key.filter((val) => currentValue.value[val] == undefined).length == 0) {
                    step.value = keys[i].step
                    setTimeout(function () {
                        let elem = document.getElementById(keys[i].id);
                        if (elem)
                            elem.scrollIntoView({ behavior: 'smooth' }, true);
                    }, 500);
                    break
                }
            }
        },
            { deep: true }
        );

        return { form, step, clientDataToShowElem, slotHoraire, slotHoraireListe }
    },
    data() {
        return {
            isEditable: true,
            uuid: uuid.v1(),
            selectedAccessoire: undefined,
            headers: [
                { text: "Référence", value: "PIECE_REFERENCE", sortable: true },
                { text: "Désignation", value: "PIECE_DESIGNATION", sortable: true },
                { text: "Quantitée", value: "qte", sortable: true, width: '200' },
            ],
            items: []
        }
    },
    mounted() {
        if (this.itemsData) {
            this.preloadData()
            if (this.itemsData['Statut'] == 140)
                this.isEditable = false
        } else {
            this.form["date"] = moment().format('YYYY-MM-DD')
            this.form["timeStart"] = moment().set('m', 0).format('HH:mm')
            this.form["timeEnd"] = moment().add(1, 'h').set('m', 0).format('HH:mm')
        }
    },
    components: {
        Vue3EasyDataTable
    },
    methods: {
        addSlotHoraire() {
            if (Object.keys(this.slotHoraire).length == 4) {
                this.slotHoraireListe.push(this.slotHoraire)
                this.slotHoraire = {}
            }
        },
        preloadData() {
            let that = this

            this.form["id"] = this.itemsData['id']
            this.form['client'] = this.clientListe.find((val) => val.SOCIETE == this.itemsData.societeliv)
            this.form["link"] = this.itemsData['_LINK MOTEUR']
            this.form["materiel"] = this.parcListe.find((val) => val.MATRICULE == this.itemsData["N° MATR INT"])
            this.form["Horametre"] = this.itemsData['Horametre']
            this.form["Kilometrage"] = this.itemsData['Kilometrage']
            this.form["themeDevis"] = this.itemsData['Theme devis']


            this.form['technicien'] = this.itemsData['Techniciens_Nom(1)']
            let orTable

            if (this.itemsData['Table'] == 'SAV_FICHE') {
                orTable = 'get_or_1_Liste_byLink'
                this.form["timeStart"] = moment(this.itemsData['Debut']).format('hh:mm:ss')
                this.form["timeEnd"] = moment(this.itemsData['Fin']).format('hh:mm:ss')
                this.form["date"] = moment(this.itemsData['Debut']).format('DD/MM/YYYY')

                this.slotHoraireListe.push(
                    { 'date': this.form["date"], 'timeStart': this.form["timeStart"], 'timeEnd': this.form["timeEnd"], 'technicien': this.technicienListe.find((val) => val.NOM_TECHNICIEN == this.itemsData["Techniciens_Nom(1)"]), }
                )
            } else if (this.itemsData['Table'] == 'SAV_2') {
                orTable = 'get_or_2_Liste_byLink'
                if (this.itemsData['DATE RAPPORT']) {
                    let dateFormated = this.itemsData['DATE RAPPORT'].split('/')
                    this.form["date"] = dateFormated[2] + '-' + dateFormated[0] + '-' + dateFormated[1]
                }
                this.form["timeStart"] = this.itemsData['Heure Arriv(1)']
                this.form["timeEnd"] = this.itemsData['Heure dep(1)']

                this.slotHoraireListe.push(
                    { 'date': this.form["date"], 'timeStart': this.form["timeStart"], 'timeEnd': this.form["timeEnd"], 'technicien': this.technicienListe.find((val) => val.NOM_TECHNICIEN == this.itemsData["Techniciens_Nom(1)"]), }
                )
            }

            engine[orTable](this.itemsData['_LINK MOTEUR'], function (data) {
                console.log(1);
                console.log("orTable", data);
                if (data.code == 0) {
                    let moteurListe = data.data.data.map((val) => val.fieldData)
                    for (let i = 0; i < moteurListe.length; i++) {
                        let elem = that.stockListe.find((val) => val.PIECE_REFERENCE == moteurListe[i]['SAV REF PRODUIT'])
                        elem.qte = moteurListe[i]["SAV QTE PROD"]
                        that.items.push(elem)
                    }
                }
            })

        },
        verifScroll(elem) {
            if (elem) {
                return true
            } else {
                return false
            }
        },
        validForm() {
            // let that = this
            // let id = uuid.v1()
            // let link = uuid.v1()

            console.log("form", this.form);
            console.log("itemsData", this.itemsData);

            if (this.itemsData == undefined) {
                this.creatOr()
            } else {
                if (this.itemsData.Table == 'SAV_2')
                    this.editOrSAV_2()
                if (this.itemsData.Table == 'SAV_FICHE')
                    this.editOrSAV_FICHE()
            }

        },
        creatOr() {
            let that = this
            let id = uuid.v1()
            let link = uuid.v1()

            let finalObj = {
                id,
                societeliv: this.form['client']['SOCIETE'],
                // 'N° RAP COMP': '?',
                // 'N° Dev COMP': '?',
                // 'Observ': '?',
                // 'dptliv': '?',
                // 'N° serie constr.': '?',
                '_LINK MOTEUR': link,
                'Telephone': this.form['client']['TELEPHONE'],
                'villeliv': this.form['client']['VILLE_LIV'],
                // 'dptliv': clientAgency.CODE_POSTAL,
                // 'ID_Agence': clientAgency.ID_Agence,
                // 'ID_Societe': clientAgency.ID_Societe,
                'N° MATR INT': this.form['materiel']['MATRICULE'],
                'Horametre': this.form['Horametre'],
                'Kilometrage': this.form['Kilometrage'],
                'MARQUE MACHINE': this.form['materiel']['MARQUE'],
                'TYPE MACHINE': this.form['materiel']['TYPE'],
                'Theme devis': this.form['themeDevis'],
                'DATE RAPPORT': moment(this.slotHoraireListe[0]['date']).format('DD/MM/YYYY'),
                'Heure Arriv': this.slotHoraireListe[0]['timeStart'],
                'Heure dep': this.slotHoraireListe[0]['timeEnd'],
                "Status devis": 'en cours',
                "Statut": '130',
            }

            finalObj['Techniciens_Nom(1)'] = this.form.technicien.NOM_TECHNICIEN

            engine.creat_new_or(finalObj, function (data) {
                if (data.code == 0) {
                    // if (that.itemsData && that.itemsData.id)
                    //     engine.delete_or_by_id(that.itemsData.id, function () {
                    //     })
                    if (that.items.length == 0)
                        that.$emit('close')
                    for (let i = 0; i < that.items.length; i++) {
                        let moteurInfo = that.items[i]
                        let elem = {
                            id: moteurInfo.id,
                            "_LINK SAV": link,
                            "id_SAV": id,
                            "SAV REF PRODUIT": moteurInfo.PIECE_REFERENCE,
                            "SAV DESIGNATION": moteurInfo.PIECE_DESIGNATION,
                            "SAV QTE PROD": moteurInfo.qte,
                            "SAV PV UNIT": undefined,
                            "PIECE_TOTAL": undefined,
                            "FLAG_DELETE": undefined,
                            // ID_Societe: clientAgency.ID_Societe,
                            Index_Ligne: i + 1,
                        }
                        engine.creat_new_moteur_line(elem, function () {
                            if (i + 1 == that.items.length) {
                                that.$emit('close')
                            }
                        })
                    }
                }
            })
        },
        editOrSAV_2() {
            let that = this
            let id = uuid.v1()
            let link = uuid.v1()

            let finalObj = {
                id,
                societeliv: this.form['client']['SOCIETE'],
                // 'N° RAP COMP': '?',
                // 'N° Dev COMP': '?',
                // 'Observ': '?',
                // 'dptliv': '?',
                // 'N° serie constr.': '?',
                '_LINK MOTEUR': link,
                'Telephone': this.form['client']['TELEPHONE'],
                'villeliv': this.form['client']['VILLE_LIV'],
                // 'dptliv': clientAgency.CODE_POSTAL,
                // 'ID_Agence': clientAgency.ID_Agence,
                // 'ID_Societe': clientAgency.ID_Societe,
                'N° MATR INT': this.form['materiel']['MATRICULE'],
                'Horametre': this.form['Horametre'],
                'Kilometrage': this.form['Kilometrage'],
                'MARQUE MACHINE': this.form['materiel']['MARQUE'],
                'TYPE MACHINE': this.form['materiel']['TYPE'],
                'Theme devis': this.form['themeDevis'],
                'DATE RAPPORT': moment(this.slotHoraireListe[0]['date']).format('DD/MM/YYYY'),
                'Heure Arriv': this.slotHoraireListe[0]['timeStart'],
                'Heure dep': this.slotHoraireListe[0]['timeEnd'],
                // "Status devis": 'en cours',
                "Statut": '130',
            }

            if (this.form.technicien.NOM_TECHNICIEN)
                finalObj['Techniciens_Nom(1)'] = this.form.technicien.NOM_TECHNICIEN
            else
                finalObj['Techniciens_Nom(1)'] = this.form.technicien

            engine.creat_new_or(finalObj, function (data) {
                if (data.code == 0) {
                    if (that.itemsData && that.itemsData.id)
                        engine.delete_or_by_id(that.itemsData.id, function () {
                        })
                    if (that.items.length == 0)
                        that.$emit('close')
                    for (let i = 0; i < that.items.length; i++) {
                        let moteurInfo = that.items[i]
                        let elem = {
                            id: moteurInfo.id,
                            "_LINK SAV": link,
                            "id_SAV": id,
                            "SAV REF PRODUIT": moteurInfo.PIECE_REFERENCE,
                            "SAV DESIGNATION": moteurInfo.PIECE_DESIGNATION,
                            "SAV QTE PROD": moteurInfo.qte,
                            "SAV PV UNIT": undefined,
                            "PIECE_TOTAL": undefined,
                            "FLAG_DELETE": undefined,
                            // ID_Societe: clientAgency.ID_Societe,
                            Index_Ligne: i + 1,
                        }
                        engine.creat_new_moteur_2_line(elem, function () {
                            if (i + 1 == that.items.length) {
                                that.$emit('close')
                            }
                        })
                    }
                }
            })
        },
        editOrSAV_FICHE() {
            console.log("editOrSAV_FICHE");
            let that = this
            let id = uuid.v1()
            let link = this.itemsData['_LINK MOTEUR']

            let date = this.slotHoraireListe[0]['date'].split('/')[1] + '/' + this.slotHoraireListe[0]['date'].split('/')[0] + '/' + this.slotHoraireListe[0]['date'].split('/')[2]

            let finalObj = {
                id,
                // societeliv: this.form['client']['SOCIETE'],
                'N° RAP COMP': this.itemsData['N° RAP COMP'],
                // 'N° Dev COMP': '?',
                // 'Observ': '?',
                // 'dptliv': '?',
                // 'N° serie constr.': '?',
                // '_LINK MOTEUR': link,
                // 'Telephone': this.form['client']['TELEPHONE'],
                // 'villeliv': this.form['client']['VILLE_LIV'],
                // 'dptliv': clientAgency.CODE_POSTAL,
                // 'ID_Agence': clientAgency.ID_Agence,
                // 'ID_Societe': clientAgency.ID_Societe,
                // 'N° MATR INT': this.form['materiel']['MATRICULE'],
                'Horametre': this.form['Horametre'],
                'Kilometrage': this.form['Kilometrage'],
                // 'MARQUE MACHINE': this.form['materiel']['MARQUE'],
                // 'TYPE MACHINE': this.form['materiel']['TYPE'],
                'Theme devis': this.form['themeDevis'],
                // 'DATE RAPPORT': moment(this.slotHoraireListe[0]['date']).format('DD/MM/YYYY'),
                'Fin': date + ' ' + this.slotHoraireListe[0]['timeStart'],
                'Debut': date + ' ' + this.slotHoraireListe[0]['timeEnd'],
                // "Status devis": 'en cours',
                // "Statut": '130',
            }


            engine.edit_or(finalObj, function (data) {
                console.log("edit_or", data);
                if (data.code == 0) {
                    console.log("link", link);
                    engine.delete_old_moteur_by_link(link, function () {
                        if (that.items.length == 0)
                            that.$emit('close')
                        for (let i = 0; i < that.items.length; i++) {
                            let moteurInfo = that.items[i]
                            let elem = {
                                id: moteurInfo.id,
                                "_LINK SAV": link,
                                "id_SAV": id,
                                "SAV REF PRODUIT": moteurInfo.PIECE_REFERENCE,
                                "SAV DESIGNATION": moteurInfo.PIECE_DESIGNATION,
                                "SAV QTE PROD": moteurInfo.qte,
                                "SAV PV UNIT": undefined,
                                "PIECE_TOTAL": undefined,
                                "FLAG_DELETE": undefined,
                                // ID_Societe: clientAgency.ID_Societe,
                                Index_Ligne: i + 1,
                            }
                            engine.creat_new_moteur_1_line(elem, function () {
                                if (i + 1 == that.items.length) {
                                    that.$emit('close')
                                }
                            })
                        }
                    })
                }
            })
        },
        addAcc() {
            this.selectedAccessoire['qte'] = 1
            this.items.push(this.selectedAccessoire)
            this.selectedAccessoire = undefined
        },
        clickRow(data) {
            console.log("clickRow", data);
        }
    },
    computed: {
        materielElemToShow() {
            return [{
                key: "FAMILLE",
                label: "Famille"
            },
            {
                key: "SOUS_FAMILLE",
                label: "Sous famille"
            }, {
                key: "NUMERO_SERIE",
                label: "Numéro de série"
            }, {
                key: "MARQUE",
                label: "Marque"
            }]
        }
    }
}
</script>

<style>
.edition {
    background-color: #EEEEEE;
    color: black;
    border: none !important;
}

.disabledFormData {
    background-color: white !important;
}

.stepContainer {
    background-color: #E0E0E0;
    padding: 15px;
    border-radius: 10px;
    border: solid 1px #BDBDBD;
}

.sav-form table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.sav-form td,
.sav-form th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.sav-form tr:nth-child(even) {
    background-color: #dddddd;
}
</style>