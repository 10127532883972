<template>
  <div style="" class="px-2 gestionLivraison">
    <v-row>
      <v-col cols="9" class="px-1 pt-2" style="position: relative">
        <v-row>
          <v-col cols="5" class="pr-1 pb-1">
            <itineraire-calendar :calendarOptions="calendarOptions"></itineraire-calendar>
          </v-col>
          <v-col cols="7" class="pa-0 mt-3 pb-1">
            <map-gps :mapIsLoading="mapIsLoading" :getItineraireTotalSummary="getItineraireTotalSummary"
              :itineraireOrder="itineraireOrder" :setItineraireOrderUp="setItineraireOrderUp"
              :setItineraireOrderDown="setItineraireOrderDown" :deleteFromItineraire="deleteFromItineraire"
              :getMaxPoid="getMaxPoid" :itineraireDataSaved="itineraireDataSaved"
              :gpsPositionByClient="gpsPositionByClient">
            </map-gps>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="12" class="pt-0 pb-0 pr-0">
            <itineraire-edition :mainItineraireValidTab="itineraireValidTab"
              :clearItineraireValidTab="clearItineraireValidTab" :addEvent="addEvent" :editEvent="editEvent"
              :deleteEvent="deleteEvent" :showValidatedItineraire="showValidatedItineraire" :driversListe="driversListe"
              :typeListe="typeListe" :dateChange="dateChange" :showTimePanel="showTimePanel" :getMaxPoid="getMaxPoid"
              :reloadIteneraire="reloadIteneraire" :itineraireDataSaved="itineraireDataSaved"
              :toggleTimePanel="toggleTimePanel" :itineraireOrderLastVue="getItineraireOrderLastVue"></itineraire-edition>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3" class="pb-0 pr-1 pt-2">
        <!-- :style="isPathLoaded ? 'filter: blur(6px)' : ''" -->
        <withdrawal-list :isPathLoaded="isPathLoaded" :getAgencyFiltered="getAgencyFiltered"
          :position="getClientsPosition" :itineraireOrder="getItineraireOrder" :setItineraireOrder="setItineraireOrder"
          :validItineraire="validItineraire" :showFullPath="showFullPath" :agencyFilter="agencyFilter"
          @updateFilter="updateFilter($event)" :gpsPositionByClient="gpsPositionByClient" :lastItineraireOrder="itineraireOrder"></withdrawal-list>
      </v-col>
    </v-row>
    <!-- <div
        v-if="isPathLoaded"
        style="
          top: 35%;
          left: 0%;
          right: 0%;
          position: absolute;
          font-weight: bold;
        "
        align="center"
      >
        <v-progress-circular
          style="
            background-color: white;
            border-radius: 100%;
            text-transform: uppercase;
          "
          align="center"
          class="pa-8"
          width="10"
          :size="220"
          color="#3F51B5"
          bg-color="#BDBDBD"
          indeterminate
        >
          Chargement des trajets disponible<br />
          {{ getItineraireDataSavedSorted.length }} /
          {{ position.length * (position.length - 1) - getUnknowAd }}
        </v-progress-circular>
      </div> -->
  </div>
</template>
  
<script>
import "@fullcalendar/vue3";
import "@fullcalendar/core/vdom"; // solves problem with Vite

import mapsSettings from "../hooks/maps";
import calendarData from "../hooks/calendar";
import "vuetify/styles"; // Global CSS has to be imported

import moment from "moment";
import { uuid } from "vue-uuid";
import { ref } from "@vue/reactivity";

import mapGps from "./map-gps.vue";
import itineraireCalendar from "./itineraire-calendar.vue";
import itineraireEdition from "./itineraire-edition.vue";
import withdrawalList from "./withdrawal-list.vue";

import engine from '../core/DataApi'
import _ from 'lodash'
import { computed } from "@vue/runtime-core";

export default {
  setup() {

    const mapIsLoading = ref(false)

    const {
      map,
      position,
      itineraireDataSaved,
      itineraireOrder,
      agencyFilter,
      getItineraireTotalSummary,
      itineraireValidTab,
      driversListe,
      typeListe,
      showFullPath,
      clearMap,
      validItineraire,
      showValidatedItineraire,
      eventsColor,
      getUnknowAd,
      getClientsPosition,
      getClientsAllPath,
      verifItineraire,
      gpsPositionByClient,
      itineraireOrderLastVue,
      reloadIteneraire
    } = mapsSettings();

    const getItineraireOrder = computed(function () {
      return _.cloneDeep(itineraireOrder.value)
    })

    const getItineraireOrderLastVue = computed(function () {
      return _.cloneDeep(itineraireOrderLastVue.value)
    })

    const eventClicked = function (eventData) {
      let filteredEvent = {};
      filteredEvent.name = eventData.event._def.extendedProps.name;
      filteredEvent.start = moment(eventData.event.start).format(
        "YYYY-MM-DD HH:mm"
      );
      filteredEvent = {
        ...filteredEvent,
        ...JSON.parse(JSON.stringify(eventData.event._def.extendedProps)),
      };

      filteredEvent.id = eventData.event._def.publicId;
      itineraireOrderLastVue.value = filteredEvent.itineraire
      delete filteredEvent.itineraire

      clearMap()
      itineraireOrder.value.data = []
      mapIsLoading.value = true
      for (let i = 0; i < itineraireOrderLastVue.value.length; i++) {
        itineraireOrderLastVue.value[i]
        let clientsIdInformation = getClientsAllPath.value.find((val) =>
          val.clients[0] == itineraireOrderLastVue.value[i].clients[0] && val.clients[1] == itineraireOrderLastVue.value[i].clients[1])

        itineraireOrderLastVue.value[i]['id'] = clientsIdInformation.id
        itineraireOrderLastVue.value[i]['idFrom'] = clientsIdInformation.idFrom
        itineraireOrderLastVue.value[i]['idTo'] = clientsIdInformation.idTo
        // itineraireOrderLastVue.value[i]['matriculeListeSortie'] = clientsIdInformation.matriculeListeSortie
        // itineraireOrderLastVue.value[i]['matriculeListeStock'] = clientsIdInformation.matriculeListeStock


        verifItineraire(_.cloneDeep(itineraireOrderLastVue.value[i]), i + 1, itineraireOrderLastVue.value.length, function () {
          mapIsLoading.value = false
        })
      }

      itineraireValidTab.value = _.cloneDeep(filteredEvent)
    };

    const { calendarEvents, calendarOptions, convertEventToFMdata, updateCalendarData } = calendarData(eventClicked);

    return {
      map,
      position,
      // getItineraireDataSavedSorted,
      itineraireDataSaved,
      itineraireOrder,
      agencyFilter,
      getItineraireTotalSummary,
      itineraireValidTab,
      driversListe,
      typeListe,
      showFullPath,
      clearMap,
      validItineraire,
      calendarOptions,
      calendarEvents,
      showValidatedItineraire,
      eventsColor,
      getUnknowAd,
      getClientsPosition,
      getClientsAllPath,
      verifItineraire,
      gpsPositionByClient,
      itineraireOrderLastVue,
      reloadIteneraire,
      convertEventToFMdata,
      updateCalendarData,
      mapIsLoading,
      getItineraireOrder,
      getItineraireOrderLastVue
    };
  },
  data() {
    return {
      showTimePanel: false,
      firstLoad: true,
    };
  },
  watch: {
    isPathLoaded(val) {
      if (val == false) {
        this.clearMap();
        this.firstLoad = false;
      }
      this.clearMap();
    },
  },
  onBeforeUnmount() {
    if (this.map) {
      this.map.remove();
    }
  },
  computed: {
    isPathLoaded() {
      return false
      // if (this.firstLoad == false) return false;
      // if (this.getAgencyFiltered.length == 0) return true;
      // if (
      //   this.getItineraireDataSavedSorted.length >=
      //   this.position.length * (this.position.length - 1) - this.getUnknowAd
      // )
      //   return false;
      // else return true;
    },
    getAgencyFiltered() {
      if (this.agencyFilter != undefined) {
        let agencyFiltered = []
        for (let i = 0; i < this.getClientsAllPath.length; i++) {
          let clients = this.getClientsAllPath[i].clients
          if (this.agencyFilter.client) {
            if (clients[0].includes(this.agencyFilter.client)) {
              agencyFiltered.push(this.getClientsAllPath[i])
            }
          }
          else {
            if (clients[0].includes(this.agencyFilter.clients[1])) {
              agencyFiltered.push(this.getClientsAllPath[i])
            }
          }
        }
        return agencyFiltered
      } else {
        return this.getClientsAllPath
      }
    },
  },
  components: {
    mapGps,
    itineraireCalendar,
    itineraireEdition,
    withdrawalList,
  },

  methods: {
    updateFilter(data) {
      console.log("updateFilter", data);
      this.agencyFilter = data
    },
    clearItineraireValidTab() {
      this.itineraireValidTab = undefined;
    },
    setItineraireValidTab(key, value) {
      this.itineraireValidTab[key] = value;
    },
    getMaxPoid(poidList) {
      let max = 0;
      let add = 0;
      for (let i = 0; i < poidList.length; i++) {
        add += poidList[i];
        if (add > max) {
          max = add;
        }
      }
      return max;
    },
    deleteEvent(event) {
      let that = this
      engine.deleteEvent(event, function () {
        that.updateCalendarData()
      })
      let findedEventIndex = this.calendarEvents.findIndex(
        (val) => val.id == event.id
      );
      this.calendarEvents.splice(findedEventIndex, 1);
      this.itineraireValidTab = undefined;
      this.itineraireOrder.data = []
    },
    editEvent(event, itineraire) {
      let that = this
      let findedEventIndex = this.calendarEvents.findIndex(
        (val) => val.id == event.id
      );
      event.title = this.getEventTitle(event);
      event.color = this.eventsColor[event.type];
      this.calendarEvents[findedEventIndex] = event;
      this.itineraireValidTab = undefined;

      let fmData = this.convertEventToFMdata(event)
      fmData['recap'] = JSON.stringify(itineraire)

      engine.editEvent(fmData, function () {
        that.updateCalendarData()
      });
      this.itineraireOrder.data = []
    },
    addEvent(event, itineraire) {
      let that = this
      if (event) {
        event["id"] = uuid.v1();
        event.title = this.getEventTitle(event);
        event.color = this.eventsColor[event.type];

        this.calendarEvents.push(event);
      }

      let fmData = this.convertEventToFMdata(event)
      fmData['recap'] = JSON.stringify(itineraire)

      engine.addEvent(fmData, function () {
        that.updateCalendarData()
      });
      this.itineraireValidTab = undefined;
      this.itineraireOrder.data = []

    },
    // convertEventToFMdata(event) {
    //   return {
    //     event_id: event.id,
    //     nom: event.name,
    //     description: event.title,
    //     debut: event.start,
    //     debut_timestamp: parseInt(moment(event.start).format('x')),
    //     temps: event.time,
    //     distance: event.distance,
    //     poid: event.poid.join('|'),
    //     chauffeur: event.driver,
    //     // recap: JSON.stringify(itineraire)
    //   }
    // },
    getEventTitle(event) {
      let title =
        event.name.toUpperCase() +
        " | " +
        event.distance +
        " | " +
        event.time +
        " | " +
        this.getMaxPoid(event.poid) +
        " Kg | " +
        // event.type +
        // " | " +
        event.driver;
      return title;
    },
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    dateChange(data, item) {
      item.start = moment(data).format("YYYY-MM-DD HH:mm");
    },
    setItineraireOrder(elem) {
      let newElem = JSON.parse(JSON.stringify(elem))
      // newElem['poid'] = 0
      this.verifItineraire(newElem)
    },
    setItineraireOrderUp(elem) {
      let index = this.itineraireOrder.data.findIndex(
        (val) => val.idItineraire == elem.idItineraire
      );
      this.itineraireOrder.data.splice(index, 1);
      this.itineraireOrder.data.splice(index - 1, 0, elem);
    },
    setItineraireOrderDown(elem) {
      let index = this.itineraireOrder.data.findIndex(
        (val) => val.idItineraire == elem.idItineraire
      );
      this.itineraireOrder.data.splice(index, 1);
      this.itineraireOrder.data.splice(index + 1, 0, elem);
    },
    deleteFromItineraire(elem) {
      elem.poid = 0;
      let index = this.itineraireOrder.data.findIndex(
        (val) => val.idItineraire == elem.idItineraire
      );
      this.itineraireOrder.data.splice(index, 1);
    },
  },
};
</script>
  
<style>
@import "../style.css";
</style>