<template>
  <v-dialog width="auto" v-model="openLastVerif">
    <v-col>
      <v-card class="pa-2" align="center">
        <v-col cols="10" sm="8" md="10">
          <h2 style="text-align: left;">
            Valider le compte rendu du l'état des lieux. <br><span style="color:red"> cette action est irréversible</span>
          </h2>
        </v-col>
        <v-col align=center>
          <v-row justify="center" align="center">
            <v-col>
              <v-btn color="#66BB6A" style="color:white;height: 50px; width: 200px; font-size: 20px;"
                @click="save('signatureLink')">
                Oui
              </v-btn>
            </v-col>
            <v-col>
              <v-btn color="#EF5350" style="color:white;height: 50px; width: 200px; font-size: 20px;"
                @click="openLastVerif = false">
                Non
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-col>
  </v-dialog>
  <v-dialog v-model="verifIsOpen" width="100%" max-width="470px" persistent>
    <v-col align="center">
      <v-card style="" width="100%" max-width="500px" >
        <div style="background-color: #CFD8DC;" class="pa-2">
          <v-text-field hide-details label="Nom du signataire *" variant="solo" v-model="signataire"></v-text-field>
        </div>
        <v-icon style="position: absolute; right: 2px; top: 2px; " @click="verifIsOpen = false" color="#E57373">
          mdi-close-circle
        </v-icon>
        <div v-if="signataire && signataire.length > 0">
          <div style="background-color: #CFD8DC;border-bottom: solid 1px lightgrey;">
            <h3> <v-icon>mdi-arrow-down-bold</v-icon> Bloque de signature <v-icon>mdi-arrow-down-bold</v-icon></h3>
          </div>
          <signature-pad ref="signatureLink" style="height: 200px;width: 470px;background-color: #ECEFF1;" :options="{
            minWidth: 1,
            maxWidth: 1,
            onBegin: () => {
              $refs['signatureLink'].resizeCanvas();
            },
          }" />
          <v-card color="#CFD8DC" tile style="position: absolute; bottom: 0px; right: 0px;" width="100%">
            <v-col align="center">
              <v-btn text elevation="0" color="green" class="mx-2" @click="openLastVerif = true">
                <v-icon>mdi-content-save</v-icon> Valider ma signature
              </v-btn>
              <v-btn color="blue" class="mx-2" variant="tonal" @click="undo('signatureLink')" align="right">
                <v-icon>mdi-undo</v-icon>
              </v-btn>
              <v-btn color="red" variant="tonal" class="mx-2" @click="clear('signatureLink')">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-col>
          </v-card>
        </div>
        <div v-else style="padding-top:200px" align=center>
          <div style="color: red; font-size: 15px;font-weight: bold;">
            <v-icon>mdi-arrow-up-bold</v-icon>
            Nom du signataire obligatoire
            <v-icon>mdi-arrow-up-bold</v-icon>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-dialog>
  <div style=" height: 100vh; background-color: #ECEFF1; position: fixed; top: 0px;left: 0px; width: 100vw;" align=center>
    <div v-if="isLoading || view == 0"
      style="background-color: rgb(0,0,0,0.5); height: 100vh; width: 100vw; position:absolute; top: 0px; left: 0px; z-index: 3;">
      <v-progress-circular style="margin-top: 40vh;" :size="170" :width="7" color="#CFD8DC" indeterminate>
        Chargement
      </v-progress-circular>
    </div>
    <v-col cols="12" sm="10" md="8">
      <h1 style="text-transform: uppercase;" class="pt-4">
        Application de signature en ligne
      </h1>
      <h3 v-if="view == 1" align="left" style="padding-top: 40px;">
        En validant votre signature vous vous engagez à avoir pris connaissance de l'états des lieux concernant le contrat
        <span style="font-weight: bold; color: #66BB6A;"> {{ contratData.CONTRAT }}</span> et d'approuver l'ensemble du
        contenue pour le materiel <span style="font-weight: bold; color: #66BB6A;">{{ contratData.MATRICULE }}</span>.
      </h3>
      <h3 v-if="view == 2" align="left"
        style="padding-top: 40px; background-color: #DCEDC8; padding:30px;border-radius: 5px;margin-top: 30vh;">
        Votre signature est validé et vient d'être ajouté sur l'état des lieux concernant le contrat
        <span style="font-weight: bold; color: #66BB6A;"> {{ contratData.CONTRAT }}</span> etle materiel <span
          style="font-weight: bold; color: #66BB6A;">{{ contratData.MATRICULE }}</span>.
      </h3>
      <h3 v-if="view == 3" align="left"
        style="padding-top: 40px; background-color: #FFCDD2; padding:30px;border-radius: 5px;margin-top: 30vh;">
        Une erreur c'est produit aucune modification ou signature n'a été ajouté sur l'état des lieux
        <br> Nous vous invitons à essayer à nouveau ou à nous contacter.
      </h3>
      <h3 v-if="view == 4" align="left"
        style="padding-top: 40px; background-color: #FFCDD2; padding:30px;border-radius: 5px;margin-top: 30vh;">
        Il existe déja une signature pour le contrat <span style="font-weight: bold; color: #66BB6A;"> {{
          contratData.CONTRAT }}</span>
        consernant le materiel <span style="font-weight: bold; color: #66BB6A;">{{ contratData.MATRICULE }}</span>.
      </h3>
      <h3 v-if="view == 5" align="left"
        style="padding-top: 40px; background-color: #FFCDD2; padding:30px;border-radius: 5px;margin-top: 30vh;">
        L'url de signature de l'état des lieux est expiré.
        <br> Nous vous invitons à essayer de nous contacter.
      </h3>
    </v-col>
    <v-btn v-if="view == 1" v-model="verifIsOpen" color="#7986CB" @click="verifIsOpen = true"
      style="font-weight: bold; color: white; margin-top: 25vh; font-size: 25px; height: 100px;">
      Ouvrir l'outil de signature
    </v-btn>
    <div align="right">
      <div style="position: absolute; bottom: 10px; right: 30px; font-weight: bold;">
        Platforme de signature en ligne
      </div>
    </div>
  </div>
</template>

<script>

import SignaturePad from "vue3-signature-pad";
import engine from "../core/index";
import moment from 'moment'

export default {
  data() {
    return {
      signataire: undefined,
      view: 1,
      isLoading: false,
      openLastVerif: false,
      verifIsOpen: false,
      id: undefined,
      contratData: {},
      signatureData: undefined
    }
  },
  components: {
    SignaturePad
  },
  mounted() {
    let that = this
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id
      engine.get_signature_data(this.id, function (data) {
        if (data.code == 0) {
          that.contratData = data.data.data[0].fieldData
          console.log("contrat", that.contratData);
          if (moment(that.contratData.END_DATE).isBefore(moment())) {
            that.view = 5
            that.isLoading = false
          } else if (that.contratData.VALIDE == 'OUI') {
            that.view = 4
            that.isLoading = false
          } else {
            that.view = 1
          }
        } else {
          that.view = 3
          that.isLoading = false
        }
      })
    } else {
      this.view = 3
      this.isLoading = false
    }
  },
  methods: {
    formatDate(date, format) {
      return moment(date).format(format)
    },
    undo(key) {
      if (this.$refs[key].length == undefined)
        this.$refs[key].undoSignature();
      else
        this.$refs[key][0].undoSignature();
    },
    save(key) {
      let that = this
      this.isLoading = true
      this.openLastVerif = false
      this.verifIsOpen = false

      const { isEmpty, data } = this.$refs[key].saveSignature();
      if (isEmpty === false) this.signatureData = data;
      engine.save_signature(this.id, this.signatureData, this.signataire, function (date2) {
        if (date2.code == 0) {
          that.view = 2
        } else {
          that.view = 3
        }
        that.isLoading = false
      })
    },
    clear(key) {
      if (this.$refs[key].length == undefined)
        this.$refs[key].clearSignature();
      else
        this.$refs[key][0].clearSignature();
    },
  },
  computed: {
    getRefs() {
      return this.$refs
    }
  }
}
</script>

<style></style>