<template>
  <v-card v-if="verifCancelEvent === false" class="pa-2">
    <v-col class="pa-0">
      <table style="width: 100%; font-size: 16px; border:solid 1px lightgrey;background-color: #EEEEEE;" class="">
        <tr v-for="(elem, idx) in getFormatedEventData" :key="idx" :style="
          idx % 2 === 0
            ? 'background-color:#EEEEEE;color:black;'
            : 'background-color:white'
        ">
        <v-row class="px-2" justify="center" align="center">
          <v-col cols="3" align="left">
            {{ elem.key }}
          </v-col>
          <v-col cols=""  align="left">
            {{ elem.value }}
          </v-col>
        </v-row>
          <!-- <div style="height: 100%; padding: 2px" class="px-1 py-1">
            <div style="float: left;">
              {{ elem.key }}
            </div>
            <div v-if="elem.value" style=" float: right; height: 100%;">
              {{ elem.value }}
            </div>
            <br />
          </div> -->
        </tr>
      </table>
    </v-col>
    <v-col class="px-0" v-if="infoTabSorted" style=" font-size: 17px;">
      <div style="max-height: 25vh;overflow-y: auto;">
        <v-row style="
          background-color: #EEEEEE;
              border: solid 1px lightgrey;
              width: 100%;
              margin: 10px 0px;
              padding: 1px;
            ">
            <v-col v-for="elem in infoTabSorted.headers" :key="elem" style="text-transform: uppercase;">
              {{ elem }}
            </v-col>
        </v-row>
        <table style="
          background-color: #EEEEEE;
              border: solid 1px lightgrey;
              width: 100%;
              margin: 10px 0px;
              padding: 1px;
            ">

          <tr v-for="line in infoTabSorted.items" :key="line">
            <td v-for="elem in line" :key="elem"
              style="padding: 5px; border: solid 1px lightgrey;background-color:white;">
              {{ elem }}
            </td>
          </tr>
        </table>
      </div>
    </v-col>
    <v-col :class="!infoTabSorted ? 'mt-2' : ''">
      <v-row style="
            background-color: #EEEEEE;
            border: solid 1px lightgrey;
            font-weight: bold;
          " justify="center" align="center">
        <v-col cols="3" align="center" class="button-state" style="border-radius:0px;"
          :style="eventState == '110' ? getStaturBarStyle : ''">
          Planifié
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="3" align="center" class="button-state" style="border-radius:0px;"
          :style="eventState == '120' ? getStaturBarStyle : ''">
          Envoyé sur tablette
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="3" align="center" class="button-state" style="border-radius:0px;"
          :style="eventState == '130' ? getStaturBarStyle : ''">
          Récupéré
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="3" align="center" class="button-state" style="border-radius:0px;"
          :style="eventState == '140' ? getStaturBarStyle : ''">
          Terminé
        </v-col>
      </v-row>
    </v-col>
    <div style="
            width: 100%;
            border: solid 1px lightgrey;
            height: 11px;
          " class="mt-1">
      <div style="height: 9px" :style="getStaturBarStyle"></div>
    </div>
    <div align="right" style="margin-top: 10px; width: 100%" class="mt-3">
      <div align="left" v-if="isRoot">
        <v-btn v-if="eventState <= 130" @click="verifCancelEvent = true" class="modal-btn ma-1" variant="flat"
          style="background-color: #bf360c;color:white">
          Annuler la planification
        </v-btn>
        <v-btn v-if="eventState <= 110" @click="sendToTablette()" class="modal-btn ma-1" variant="flat"
          style="color:white" :style="{ 'background-color': this.eventColorById[120].color }">
          Envoyer vers
          <span class="mx-2" style="
                  font-weight: bold;
                  font-size: 15px;
                  color: purple;
                  background-color: #f5f5f5;
                  padding: 0px 5px;
                  border-radius: 2px;
                ">
            {{ this.eventData["Technicien"][0] }}
          </span>
          tablette
        </v-btn>
        <v-btn variant="flat" class="modal-btn ma-1" style="background-color: #5c6bc0;color:white;" :href="getAccenUrl">
          Ouvrir dans ACCEN
        </v-btn>
      </div>
      <v-btn @click="closeModal" class="modal-btn" style="margin: 5px; background-color: #bf360c;color:white"
        variant="flat">
        Fermer
      </v-btn>
    </div>
  </v-card>
  <v-card v-else align="center">
    <v-col>
      <h2>Êtes-vous sûr de vouloir annuler cette intervention ?</h2>
      <div>
        <button style="
              width: 40%;
              padding: 5px;
              margin: 1px;
              cursor: pointer;
              background-color: #4caf50;
              color: white;
              border-style: none;
            " @click="cancelEvent">
          Oui
        </button>
        <button style="
              width: 40%;
              padding: 5px;
              margin: 1px;
              cursor: pointer;
              background-color: #bf360c;
              color: white;
              border-style: none;
            " @click="verifCancelEvent = false">
          Non
        </button>
      </div>
    </v-col>
  </v-card>
</template>

<script>
import planningTechnicien from "../core/index";
import moment from "moment";

export default {
  data() {
    return {
      eventState: 100,
      verifCancelEvent: false,
      infoTabSorted: undefined,
    };
  },
  props: {
    eventData: {},
    eventColorById: {},
    isRoot: {},
  },
  methods: {
    setState(val) {
      this.eventState = val;
    },
    closeModal() {
      this.$emit("close");
    },
    saveModal() {
      this.$emit("save", { id: this.eventData.id, Statut: this.eventState });
    },
    sendToTablette() {
      this.$emit("save", { id: this.eventData.id, Statut: "120" });
    },
    cancelEvent() {
      this.$emit("save", { id: this.eventData.id, Statut: "100" });
    },
  },
  mounted() {
    let that = this;
    planningTechnicien.get_event_info_by_event_link(
      this.eventData["_LINK MOTEUR"],
      function (data) {
        if (data.code == 0) {
          let infoTabSorted = {
            headers: ["Référence", "Désignation", "Quantitée"],
            items: [],
          };
          let infoTab = data.data.data;
          for (let i = 0; i < infoTab.length; i++) {
            infoTabSorted.items.push([
              infoTab[i].fieldData["SAV REF PRODUIT"],
              infoTab[i].fieldData["SAV DESIGNATION"],
              infoTab[i].fieldData["SAV QTE PROD"],
            ]);
          }
          that.infoTabSorted = infoTabSorted;
        }
      }
    );
    this.eventState = this.eventData["Statut"];
  },
  computed: {
    getAccenUrl() {
      return (
        // "fmp://172.16.1.42/Sav.fmp12?script=affiche_contrat&param=" +
        "fmp://10.20.23.11/Sav.fmp12?script=affiche_or&param=" +
        this.eventData["N° RAP COMP"]
      );
    },
    isBtnSelectedStyle() {
      return "background-color: grey;color: white;";
    },
    getStaturBarStyle() {
      if (this.eventState == 110)
        return {
          width: "25%",
          "background-color": this.eventColorById[this.eventState].color,
          color: 'white'
        };
      if (this.eventState == 120)
        return {
          width: "50%",
          "background-color": this.eventColorById[this.eventState].color,
          color: 'white'
        };
      if (this.eventState == 130)
        return {
          width: "75%",
          "background-color": this.eventColorById[this.eventState].color,
          color: 'white'
        };
      if (this.eventState == 140)
        return {
          width: "100%",
          "background-color": this.eventColorById[this.eventState].color,
          color: 'white'
        };
      return {
        width: "0%",
        "background-color": this.eventColorById[this.eventState].color,
        color: 'white'
      };
    },
    getFormatedEventData() {
      let formatedEventData = [];
      let keyNameToshow = {
        "N° RAP COMP": { value: "N° RAP COMP", idx: 0 },
        societeliv: { value: "Société", idx: 1 },
        adliv: { value: "Adresse", idx: 2 },
        villeliv: { value: "Ville", idx: 3 },
        Telephone: { value: "Téléphone", idx: 4 },
        Debut: { value: "Début", idx: 5 },
        Fin: { value: "Fin", idx: 6 },
        Technicien: { value: "Technicien", idx: 7 },
        "Theme devis": { value: "Travaux demandé", idx: 8 },
        "N° MATR INT": { value: "N° MATR INT", idx: 9 },
        "MARQUE MACHINE": { value: "MARQUE MACHINE", idx: 10 },
        "N° serie constr.": { value: "N° SERIE CONSTR", idx: 11 },
        "TYPE MACHINE": { value: "TYPE MACHINE", idx: 12 },
        Message_Interne: { value: "Message interne", idx: 13 },
      };
      for (const [key, value] of Object.entries(this.eventData)) {
        if (
          key !== "id" &&
          key !== "_LINK MOTEUR" &&
          key !== "Statut" &&
          key !== "compadliv" &&
          key !== "ID_Agence"
        ) {
          let name = key;
          let dataValue = value;
          if (keyNameToshow[key].value) {
            name = keyNameToshow[key].value;
          }
          if (key === "adliv") {
            if (this.eventData["compadliv"])
              dataValue += " / " + this.eventData["compadliv"];
          }
          if (key === "Debut" || key === "Fin") {
            dataValue = moment(dataValue).format("DD/MM/YYYY HH:mm");
          }
          if (key === "Technicien") {
            try {
              dataValue = dataValue.filter((val) => val != null).join(" - ");
            } catch {
              true;
            }
          }
          formatedEventData.push({
            key: name,
            value: dataValue,
            idx: keyNameToshow[key].idx,
          });
        }
      }
      return formatedEventData.sort((a, b) => {
        return a.idx - b.idx;
      });
    },
  },
};
</script>

<style>

</style>